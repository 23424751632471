/* eslint-disable no-console */

import { getUserTokenFromStorage } from './Helpers';

export const setVieroListeners = function() {

    window.VieroToolkitUtils.maintainPlaybackLicense(() => {
        return fetch(process.env.REACT_APP_API_BASE_URI + '/viero/license', {
            method:  'POST',
            body:    JSON.stringify({ global: true}),
            headers: {
                'Content-Type': 'application/json'
            },
        })
            .then((res) => res.json());
    });

    window.VieroNotificationCenter.addEventListener(window.VieroUploader.EVENT.DID_START_UPLOAD, function (evt) {
        if (evt.detail && evt.detail.err) {
            console.log(evt.detail.err.error.message);
        }
        if (evt.detail) {
            console.log('from viero: Upload starting: ', evt.detail.uploader._statOverall);
        }
    });

    window.VieroNotificationCenter.addEventListener(window.VieroUploader.EVENT.DID_PROGRESS_UPLOAD_CHUNK, function (evt) {
        if (evt.detail && evt.detail.err) {
            console.log(evt.detail.err.error.message);
        }
        if (evt.detail) {
            console.log('from viero: Chunk upload event: ', evt.detail.uploader._statOverall);
        }
    });

    window.VieroNotificationCenter.addEventListener(window.VieroUploader.EVENT.DID_DID_END_UPLOAD_CHUNK, function (evt) {
        if (evt.detail && evt.detail.err) {
            console.log(evt.detail.err.error.message);
        }
        if (evt.detail) {
            console.log('from viero: Chunk uploaded event: ', evt.detail.uploader._statOverall);
        }
    });

    window.VieroNotificationCenter.addEventListener(window.VieroUploader.EVENT.DID_END_UPLOAD, function (evt) {
        if (evt.detail && evt.detail.err) {
            console.log(evt.detail.err.error.message);
        }
        if (evt.detail) {
            console.log('from viero: Upload finished: ', evt.detail.uploader._uploadable.nodeId);
        }
    });
    window.VieroNotificationCenter.addEventListener(window.VieroUploader.EVENT.DID_BECOME_IDLE, function () {
        //console.log('Uploads finished');
        console.log('from viero: Idle');
    });
};

export const uploadFiles = function(files) {

    return window.VieroUploader.preProcess(Array.from(files))
        .then((collated) => {
            if (!collated.supported.length) {
                throw 'None of the picked files are supported.';
            }
            return Promise.all([
                collated,
                window.VieroUploader.preProcessedRepsToTouchables(collated.supported),
            ]);
        })
        .then(([collated, touchables]) => {
            return Promise.all([
                collated,
                fetch(process.env.REACT_APP_API_BASE_URI + '/viero/touch', {
                    method:  'POST',
                    body:    JSON.stringify({ files: touchables }),
                    headers: {
                        'Content-Type':  'application/json',
                        'Authorization': 'Bearer ' + getUserTokenFromStorage()
                    },
                }),
            ]);
        })
        .then(([collated, res]) => Promise.all([collated, res, res.clone().json() ]))
        .then(([collated, touchRes, clonedRes]) => {
            var uploadables = window.VieroUploader.generateUploadables(collated.supported, clonedRes);
            window.VieroUploader.enqueueUploadables(uploadables);
            return touchRes;
        }).then((res) => {
            return res;
        });
};

export const getStatusOfUpload = function(nodeId) {
    return window.VieroToolkitUtils.statusOf([nodeId])
        .then((res) => {
            var status = res[nodeId];
            switch (status.s) {
                case window.VieroToolkitUtils.NODE_STATUS.ERROR: {
                    // eg. transcode has failed
                    console.log('from viero: error');
                    console.log('from viero:', nodeId);
                    return 'error';
                }
                case window.VieroToolkitUtils.NODE_STATUS.READY: {
                    console.log('from viero: ready');
                    console.log('from viero:', nodeId);
                    return 'ready';
                }
                case window.VieroToolkitUtils.NODE_STATUS.ALLOCATED:
                    console.log('from viero: allocated');
                    console.log('from viero:', nodeId);
                    return 'allocated';
                case window.VieroToolkitUtils.NODE_STATUS.UPLOADING:
                    console.log('from viero: uploading');
                    console.log('from viero:', nodeId);
                    return 'uploading';
                case window.VieroToolkitUtils.NODE_STATUS.ENQUEUED:
                    console.log('from viero: enqueued');
                    console.log('from viero:', nodeId);
                    return 'enqueued';
                case window.VieroToolkitUtils.NODE_STATUS.PROCESSING:
                    console.log('from viero: processing');
                    console.log('from viero:', nodeId);
                    return 'processing';
                default: {
                    return status.s;
                }
            }
        });
};

export const getUrlAndLicence = function(nodeId, poster, scaling, type) {

    var appleTech = window.bowser.safari || 'iOS' === window.bowser.osname;
    var manifest = appleTech ? window.VieroToolkitUtils.MANIFEST.HLS : window.VieroToolkitUtils.MANIFEST.DASH;
    var url = window.VieroToolkitUtils.urlOf(nodeId, type, poster, scaling, manifest);
    var resultObject = {keySystems: {'org.w3.clearkey': window.VieroToolkitUtils.licenseUrlOf(nodeId)}, url: url, manifest: manifest, appleTech};
    //console.log('viero url =', url);
    //console.log('viero response resultObject =', resultObject);
    return resultObject;

};

export const getVideoThumbnail = function(nodeId, at, scalerParams) {
    let url = window.VieroToolkitUtils.videoSnapshotUrlOf(nodeId, at, scalerParams);
    return url;
};
