import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import './CapsuleView.css';
import verticalDivider from '../../assets/images/VerticalDivider@3x.png';
import mediaIcon from '../../assets/images/MediaIcon@3x.png';
import shareIcon from '../../assets/images/ShareIcon.png';
import addCardIcon from '../../assets/images/AddCardIcon@3x.png';
import { MediaView } from './MediaView';
import {AddCardView} from './AddCardView';
import { ShareView } from './ShareView';

export class CapsuleView extends Component {

    constructor (props) {
        super(props);
        this.state = {
            cardAdded: false
        };
    }

    renderCapsuleHeader = (isClose) => {
        return (
            <Row className="capsule-header">
                <Col lg="2" md="2" sm="2" xs="2" xl="2">
                    {
                        this.props.capsuleToShow.items.image_and_video && 0 < this.props.capsuleToShow.items.image_and_video.images.length &&
                        <img className="capsule-pic" src={this.props.capsuleToShow.items.image_and_video.images[0].url}/>
                    }
                </Col>
                <Col lg="8" md="8" sm="8" xs="8" className="capsule-name">{this.props.capsuleToShow.name}</Col>
                {isClose &&
                <Col lg="2" md="2" sm="2" xs="2" xl="2" className="right">
                    <div className="closeButton" onClick={() => this.props.onMenuChange('')}>Close</div>
                </Col>
                }
            </Row>
        );
    };

    renderMainCapsule = () => {
        return (
            <React.Fragment>

                {this.renderCapsuleHeader(false)}
                <Row className="capsule-options">
                    <Col className="hover" onClick={() => this.props.onMenuChange('media')}>
                        <img className="capsule-options-img" src={mediaIcon} />
                        <div className="option-text">Media</div>
                    </Col>
                    <Col className="vertical-align"><img className="vertical-divider" src={verticalDivider} /></Col>
                    <Col className="hover" onClick={() => this.props.onMenuChange('addCard')}>
                        <img className="capsule-options-img" src={addCardIcon} />
                        <div className="option-text">Add Card</div>
                    </Col>
                    <Col className="vertical-align"><img className="vertical-divider" src={verticalDivider} /></Col>
                    <Col className="hover" onClick={() => this.props.onMenuChange('shareView')}>
                        <img className="capsule-options-img"  src={shareIcon} />
                        <div className="option-text">Share</div>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }

    renderMediaView = () => {
        return (
            <React.Fragment>
                {this.renderCapsuleHeader(true)}
                <MediaView capsuleToShow={this.props.capsuleToShow} onClose={() => this.props.onMenuChange('')}/>
            </React.Fragment>);
    }

    renderShareView = () => {
        return (
            <React.Fragment>
                {this.renderCapsuleHeader(true)}
                <ShareView capsuleToShow={this.props.capsuleToShow} onClose={() => this.props.onMenuChange('')}/>
            </React.Fragment>);
    }

    renderAddCardView = () => {
        return (
            <React.Fragment>
                {this.renderCapsuleHeader(true)}
                <AddCardView
                    cardAdded={this.state.cardAdded}
                    changeCardAdded={this.changeCardAdded}
                    capsuleToShow={this.props.capsuleToShow}
                    onMenuChange={this.props.onMenuChange} 
                    setGotGroups={this.props.setGotGroups}
                    
                />

            </React.Fragment>);
    }

    renderCancel = () => {
        return (
            <Col className="add-card-cancel" 
                onClick={this.state.cardAdded ? 
                    () => {this.props.onMenuChange(''); this.changeCardAdded(false); this.props.setGotGroups(false);} : 
                    () => {this.props.onMenuChange(''); this.changeCardAdded(false);}
                }
            >
                { this.state.cardAdded ? 'OK' : 'Cancel' }
            </Col>
        );
    }

    changeCardAdded = (value) => {
        this.setState({cardAdded: value});
    }

    render() {
        switch (this.props.viewToShow) {
            case 'media':
                return this.renderMediaView();
            case 'addCard':
                return this.renderAddCardView();
            case 'shareView':
                return this.renderShareView();
            default:
                return this.renderMainCapsule();
        }
    }

}

CapsuleView.propTypes = {
    capsuleToShow: PropTypes.object,
    onMenuChange:  PropTypes.func,
    viewToShow:    PropTypes.string,
    setGotGroups:  PropTypes.func
};
