import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Container, Row, Col, Collapse } from 'reactstrap';
import './Home.css';
import {Explore} from '../explore/Explore';
import { getCapsuleName, isObjectExists, sortAlphabetically, mergeSearchResults, sortByUpdatedDate} from '../../utils/Helpers';
import HomeItemCarousel from '../explore/HomeItemCarousel';
import NavigationCarousel from '../explore/NavigationCarousel';
import { getUrlAndLicence } from '../../utils/VieroUtils';
import { capsuleActions, userActions, configActions } from '../redux/actions';
import plusButton from '../../assets/images/PlusButton.png';
import addGroupIcon from '../../assets/images/create_group.png';
import createCapsuleIcon from '../../assets/images/add_capsule.png';
import backButton from '../../assets/images/BackArrow@2x.png';
import exitIcon from '../../assets/images/exit_icon.png';
import {Helmet} from 'react-helmet';
import searchIcon from '../../assets/images/SearchIcon@2x.png';
import profileIcon from '../../assets/images/ProfileIcon.png';
import {ProfileHome} from '../userProfile/ProfileHome';
import {ModalView} from '../profile/ModalView';
import FilterGroups from '../explore/FilterGroups';
import FavoriteView from './FavoriteView';
import deleteIcon from '../../assets/images/DeleteCapsule.png';
import FeaturedComponent from './FeaturedComponent';
import RevokeGroupShares from './RevokeGroupShares';
import { ClipLoader } from 'react-spinners';
var AdminConfig = require('AdminConfig');
import { css } from '@emotion/core';
import defaultProfilePicture from '../../assets/images/background.jpeg';

class Home extends React.Component {

    _isMounted = false;
    constructor (props) {
        super(props);
        this.state = {
            gotGroups:           false,
            view:                'main',
            originalCapsules:    {add: [], remove: []},
            capsuleStates:       {},
            capsulesChanged:     false,
            capsulesOfNewGroup:  [],
            addedCapsuleStates:  {},
            groupName:           '',
            groupIndex:          0,
            isOptionsOpen:       false,
            slideIndex:          0,
            updateCount:         0,
            capsulesInCarousel:  [],
            groupResults:        [],
            capsuleResults:      [],
            groupCapsuleResult:  this.props.searchTerm !== '' ? this.props.capsules.searchResults : [],
            capsulesToAdd:       [],
            sharedCapsuleResult: [],
            offsetValue:         0,
            initialOffsetValue:  0,
            privateCapsules:     {add: [], remove: []},
            isPrivateCapsules:   false,
            isExploreGroup:      false,
            isShowMoreOpen:      true,
            preventListReload:   false,
            getMoreCapsules:     true,
            doneTyping:          true,
            searchListenerSet:   false,
            scrollListenerSet:   false,
            lastSearched:        '',
            capsules:            [],
            isMyGroupOpen:       true,
            exploreGroupsSet:    this.props.capsules.capsulesByGroup && 0 < this.props.capsules.capsulesByGroup.length,
            isGalleryView:       true,
            isFeatured:          this.getIsFeatured(),
            isFavoriteView:      false,
            isUserGroupView:     false,
            showFeatured:        true,
            isPublicAccount:     this.props.user.publicPage,
            fixSearch:           false,
            groupShareReady:     false,
            groupShareCopied:    false,
            gotGroups1:          false
        };

        this.override =  css`s
        display: absolute;
        text-align: center
        margin: 0 auto;
        border-color: red;
    `;
        this.typingTimer;

        this.mainGroup = 'Contact Media Capsules';
        this.sharedGroup = 'Shared with Me';
        this.myGroups = 'MY GROUPS';
        this.exploreGroups = 'PUBLIC GROUPS';
        this.pleaseLogin = 'Please login to create capsules';
        this.setGroups = 'Filter';
        this.mostRecent = 'Most Recent';
        this.showMoreExplore = 'show more explore groups';
        this.showLess = 'show less';
        this.showMoreGroups = 'show more groups';
        this.noResults = 'No results';
        this.publicCategory = 'Public Capsules';
        this.sharedCategory = 'Shared with Me';
        this.seeAll = 'See all';

        //this.preloadUserOne = 'xyzabc';
    }

    componentDidMount() {
        this._isMounted = true;
        if (!this.state.gotGroups) {

            if (this.props.user.publicPage === false && this.props.user.token && (localStorage.getItem('MUUserLogin')) && !this.props.user.refreshingToken &&
           !isObjectExists(this.props, 'user.userCapsules')) {
                this.props.dispatch(capsuleActions.getFavourites());
                this.props.dispatch(userActions.getCapsules());
                this.props.dispatch(capsuleActions.getSharedCapsules(100));
                this.props.dispatch(capsuleActions.getCapsuleGroups());
                this.props.dispatch(userActions.getUserCapsuleCount());
                this.props.dispatch(userActions.getSharedGroups());
                this.setState({gotGroups1: true});

            }
            this.props.dispatch(capsuleActions.getFeaturedCapsules());
            this.props.dispatch(capsuleActions.getExploreGroups());
            this.props.dispatch(userActions.getMostRecentCount());

            this.setState({gotGroups: true});
        }

    }

    componentDidUpdate(prevProps) {

        if (!this.state.gotGroups1) {
            if (this.props.user.publicPage === false && this.props.user.token && (localStorage.getItem('MUUserLogin')) && !this.props.user.refreshingToken &&
           !isObjectExists(this.props, 'user.userCapsules')) {
                this.props.dispatch(capsuleActions.getFavourites());
                this.props.dispatch(userActions.getCapsules());
                this.props.dispatch(capsuleActions.getSharedCapsules(100));
                this.props.dispatch(capsuleActions.getCapsuleGroups());
                this.props.dispatch(userActions.getUserCapsuleCount());
                this.props.dispatch(userActions.getSharedGroups());
                this.setState({gotGroups1: true});
                // eslint-disable-next-line no-console
                console.log('startup forced');
            }
        }

        if ((this.props.capsules.exploreGroups && !this.state.exploreGroupsSet) || prevProps.groupView !== 'main' && this.props.groupView === 'main') {
            const groups = this.props.capsules.exploreGroups;
            let groupList = this.getGroupList(groups);

            for (let i in groupList) {
                this.props.dispatch(capsuleActions.getCapsulesOfExploreGroup(groupList[i].id, 100));
            }
            this.setState({exploreGroupsSet: true});
        }

        if (prevProps.user.groupShareToken === '' && this.props.user.groupShareToken !== '') {
            this.setState({groupShareReady: true});
        }

        //changes when either getCapsuleByGroup(id) or getUserCapsulesOfExploreGroup(id) is called.
        //Both this.props.capsules.capsule and this.props.capsulesOfGroup[0] is set to this this group
        if (!prevProps.capsules.groupLoaded && this.props.capsules.groupLoaded === true ) {
            this.props.setCapsulesToShow(this.props.capsules.capsulesOfGroup[0].content, 'home 170');
        }

        /*if (((this.props.user.withKey && !this.props.user.withToken) || !localStorage.getItem('MUUserLogin')) && !this.state.isFeatured) {
            this.setState({isFeatured: true});
        }*/

        if (!document.getElementById('search') && this.state.searchListenerSet) {
            this.setState({searchListenerSet: false});
        }

        if (this.state.lastSearched !== '' && this.props.searchTerm === '') {
            this.setState({lastSearched: ''});
        }

        if (!prevProps.showSearch && this.props.showSearch) {
            this.setState({offsetValue: 0});
        }

        if (document.getElementById('search') && !this.state.searchListenerSet) {
            document.getElementById('search').addEventListener('keyup', (event) => {
                var inp = String.fromCharCode(event.keyCode);

                if ((/[a-zA-Z0-9-_ ]/.test(inp) || event.keyCode === 8) && event.keyCode !== 32 && this.state.lastSearched.trim() !== this.props.searchTerm.trim() && this.props.searchTerm !== '') {
                    clearTimeout(this.typingTimer);
                    this.typingTimer = setTimeout(() => {
                        if (this.props.groupView === 'main') {
                            this.topLevelSearch(this.props.searchTerm);
                        } else {
                            this.calculateSearchResult();
                        }
                        this.setState({doneTyping: true, lastSearched: this.props.searchTerm});
                    }, 500);
                }
            });

            document.getElementById('search').addEventListener('keydown', (event) => {
                var inp = String.fromCharCode(event.keyCode);

                if ((/[a-zA-Z0-9-_ ]/.test(inp) || event.keyCode === 8) && event.keyCode !== 32 && this.state.lastSearched.trim() !== this.props.searchTerm.trim() && this.props.searchTerm !== '') {
                    clearTimeout(this.typingTimer);
                    if (this.state.doneTyping === true) {
                        this.setState({doneTyping: false});
                    }
                }

            });
            this.setState({searchListenerSet: true});
        }

        if (document.getElementById('capsule-view-2')) {
            document.getElementById('capsule-view-2').addEventListener('scroll', () => {
                if ((document.getElementById('capsule-view-2').scrollTop > document.getElementById('capsule-view-2').scrollHeight * 0.85 && this.state.getMoreCapsules) && (this.props.groupView !== 'main' || (this.props.groupView === 'main' && this.props.searchTerm !== '' && this.props.showSearch))) {
                    let groupId = this.props.viewedGroup !== '' ? this.props.viewedGroup : 'all';
                    let initialOffsetValue;

                    switch (this.props.typeOfList) {
                        case 'user':
                            if (this.props.searchTerm !== '' && this.props.showSearch) {
                                this.props.dispatch(capsuleActions.searchCapsulesByName(this.props.searchTerm, false, 100, this.state.offsetValue +100, 'own', true));
                            } else {
                                this.props.dispatch(capsuleActions.getUserCapsules(100, this.state.offsetValue + 100, true));
                            }
                            break;
                        case 'shared':
                            if (this.props.searchTerm !== '' && this.props.showSearch) {
                                this.props.dispatch(capsuleActions.searchCapsulesByName(this.props.searchTerm, false, 100, this.state.offsetValue +100, 'shared', true));
                            } else {
                                this.props.dispatch(capsuleActions.getSharedCapsules(100, this.state.offsetValue +100, true));
                            }
                            break;
                        case 'topLevelSearch':
                            this.props.dispatch(capsuleActions.searchCapsulesByName(this.props.searchTerm, false, 100, this.state.offsetValue+100, 'top', true));
                            break;
                        default:
                            if (this.props.searchTerm !== '' && this.props.showSearch) {
                                this.props.dispatch(capsuleActions.searchCapsulesByName(this.props.searchTerm, groupId, 100, this.state.offsetValue +100, '', true));
                            } else {
                                this.props.dispatch(capsuleActions.getCapsulesOfExploreGroup(groupId, 100, this.state.offsetValue +100, true, ));
                            }
                            break;
                    }
                    if (this.props.searchTerm !== '' && this.props.showSearch) {
                        initialOffsetValue = this.state.initialOffsetValue;
                    } else {
                        initialOffsetValue = this.state.offsetValue + 100;
                    }
                    this.setState({getMoreCapsules: false, offsetValue: this.state.offsetValue + 100, initialOffsetValue});
                }

            });
        }

        if (!this.state.getMoreCapsules &&
            (prevProps.capsules.capsule.length !== this.props.capsules.capsule.length || this.didResultCountIncrease(prevProps.capsules.searchResults, this.props.capsules.searchResults) && !this.props.capsules.loading)) {
            this.setState({getMoreCapsules: true});
        }

        if ((!this.state.gotGroups && this.props.user.publicPage === false) || (!prevProps.capsules.groupDeleted && this.props.capsules.groupDeleted === true)) {
            if (this.props.user.token && localStorage.getItem('MUUserLogin') && this.props.user.publicPage === false) {
                this.props.dispatch(userActions.getCapsules());
            }

            this.props.dispatch(capsuleActions.getExploreGroups());
            this.props.dispatch(userActions.getMostRecentCount());
            this.props.dispatch(userActions.getUserCapsuleCount());
            this.props.dispatch(capsuleActions.getCapsuleGroups());
            this.props.dispatch(userActions.getSharedGroups());
            this.setState({gotGroups: true});
        }

        if (prevProps.capsules.sharedDeleted === false && this.props.capsules.sharedDeleted === true) {
            this.props.dispatch(capsuleActions.getSharedCapsules(100));
        }

        if (prevProps.capsules.groupSharedDeleted === false && this.props.capsules.groupSharedDeleted === true) {
            //this.props.dispatch(userActions.getSharedGroups());
        }

        {/* if ((localStorage.getItem('MUUserLogin') || localStorage.getItem('MUUser')) && !this.state.gotRecentCount) {
            this.props.dispatch(userActions.getMostRecentCount());
            this.setState({gotRecentCount: true});
        } */}

        if (prevProps.home && !this.props.home) {
            this.setState({gotGroups: false});
        }

        if (this.props.capsules.groupEdited === true || this.props.capsules.groupNameEdited === true && this.state.onlyNameChange === true) {
            if (this.props.isExploreGroup) {
                this.props.dispatch(capsuleActions.getCapsulesOfExploreGroup(this.props.viewedGroup));
                this.props.dispatch(userActions.getUserCapsuleCount());
            } else {
                this.props.dispatch(capsuleActions.getCapsulesByGroup(this.props.viewedGroup));
                this.setState({gotGroups: !this.state.gotGroups, onlyNameChange: false});
            }

            this.props.changeGroupView('groupCapsuleList', 'home 309');
        }

        //lds setUserCapsulesForExploreGroup(id, capsules) and editGroupCapsules(id, data) both update groupEdited to true
        //setUserCapsulesForExploreGroup(id, capsules) called when change explore capsule public or privateText
        //editGroupCapsules(id, data) called when adding capsule to a group from gallery (could be other times) and after groupCreated true
        if (prevProps.capsules.groupEdited === false && this.props.capsules.groupEdited === true) {
            this.setCapsulesStates();
            this.props.dispatch(userActions.setUserCapsulesGroup(this.props.groupToEdit.id, this.props.capsules.capsule));
            this.props.setCapsulesToShow(sortAlphabetically(this.props.capsules.capsule, 'home 318'));
        }

        if (prevProps.capsules.capsulesOfGroup !== this.props.capsules.capsulesOfGroup) {
            this.setCapsulesStates();
            let capsulesInCarousel = this.props.capsules.capsulesOfGroup.length > 0 ? this.props.capsules.capsulesOfGroup[0].content : [];
            this.setState({capsulesInCarousel});
        }

        if (prevProps.capsules.groupCreated === false && this.props.capsules.groupCreated === true) {
            if (this.props.groupView !== 'capsuleList') {
                this.props.setGroupToEdit(this.props.capsules.newGroup);
                this.props.setViewedGroup(this.props.capsules.newGroup.id, 'home 328');
                this.props.dispatch(userActions.setGroupInConfig(this.props.capsules.newGroup.id));
                this.props.dispatch(capsuleActions.editGroupCapsules(this.props.capsules.newGroup.id, {add: this.state.capsulesOfNewGroup}));
            }

        }

        if (prevProps.capsules.groupCreated === true && this.props.capsules.groupCreated === false) {
            this.props.setCapsulesToShow(sortAlphabetically(this.props.capsules.capsulesOfGroup[0].content, 'home 336'));
        }

        /*if (prevProps.searchTerm !== this.props.searchTerm && this.props.searchTerm !== '' && this.props.capsules.capsule.isLoaded) {
            this.calculateSearchResult();
        }*/

        if (prevProps.showSearch === false && this.props.showSearch === true) {
            if (document.getElementById('search')) {
                document.getElementById('search').setSelectionRange(0, 0);
                document.getElementById('search').focus();
            }
        }

        if (this.props.capsules.publicSet === true && prevProps.capsules.publicSet === false) {
            let cleanedGroup = {add: [...this.state.originalCapsules.add]};
            if (0 < this.props.capsules.capsulesOfGroup.length) {
                cleanedGroup = this.cleanGroupCapsules();
            }

            this.props.dispatch(capsuleActions.setUserCapsulesForExploreGroup(this.props.groupToEdit.id, cleanedGroup));
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    didResultCountIncrease = (prevSearch, currentSearch) => {
        let result = false;

        if (prevSearch.userCapsules && currentSearch.userCapsules) {
            if (prevSearch.userCapsules.length !== currentSearch.userCapsules.length ||
                prevSearch.sharedCapsules.length !== currentSearch.sharedCapsules.length ||
                prevSearch.publicCapsules.length !== currentSearch.publicCapsules.length)
                result = true;

        } else if (prevSearch.length !== currentSearch.length) {
            result = true;
        }

        return result;
    }

    findPrivateCapsules = () => {
        let cleanedGroup = this.state.originalCapsules;
        let privateCapsules = {add: [], remove: []};
        let capsulesOfGroup = this.props.capsules.capsulesOfGroup[0].content;
        let userCapsules = [...this.props.user.userCapsules];
        if (0 < capsulesOfGroup.length) {
            cleanedGroup = this.cleanGroupCapsules();
        }

        for (let i in userCapsules) {
            if (-1 !== cleanedGroup.add.indexOf(userCapsules[i].id) && userCapsules[i].public === false) {
                privateCapsules.add.push(userCapsules[i].id);
            }

            if (-1 !== cleanedGroup.remove.indexOf(userCapsules[i].id) && userCapsules[i].public === true) {
                privateCapsules.remove.push(userCapsules[i].id);
            }
        }
        this.setState({privateCapsules});

    }

    setCapsulesToPublicAndSave = (type) => {
        if (!type) {
            this.props.dispatch(capsuleActions.setCapsulesToPublic(this.state.privateCapsules));
        } else if (type === 'public') {
            let privateCapsules = {...this.state.privateCapsules};
            privateCapsules.remove = [];
            this.props.dispatch(capsuleActions.setCapsulesToPublic(privateCapsules));
        }

    }

    getUserGroupCapsules = (id) => {
        for (let i in this.props.user.userGroupCapsules) {
            if (this.props.user.userGroupCapsules[i].id === id) {
                return this.props.user.userGroupCapsules[i].content;
            }
        }
        return [];
    }

    onSave = () => {
        if (this.props.groupToEdit.name) {
            let listToShow = [];
            if (this.state.isCreate) {
                this.props.dispatch(capsuleActions.createCapsuleGroup(this.props.groupToEdit.name));
            } else
            if (!this.props.isExploreGroup) {
                this.props.dispatch(capsuleActions.updateCapsuleGroup(this.props.groupToEdit.id, {name: this.props.groupToEdit.name}));
                if (!this.state.capsulesChanged) {
                    this.setState({onlyNameChange: true});
                }
            }

            if (this.state.capsulesChanged && !this.state.isCreate) {
                let cleanedGroup = {add: [...this.state.originalCapsules.add], remove: [...this.state.originalCapsules.remove]};

                this.setState({capsuleUpdates: true});
                cleanedGroup = this.cleanGroupCapsules();

                if (this.props.capsules.capsulesOfGroup[0].id === this.props.groupToEdit.id) {
                    for (let i in this.props.capsules.capsulesOfGroup[0].content) {
                        if (cleanedGroup.remove === []) {
                            listToShow.push(this.props.capsules.capsulesOfGroup[0].content[i]);
                        } else {
                            let found = false;
                            for (let j in cleanedGroup.remove) {
                                if (cleanedGroup.remove[j].indexOf(this.props.capsules.capsulesOfGroup[0].content[i].id) !== -1) {
                                    found = true;
                                    break;
                                }
                            }
                            if (!found) {
                                listToShow.push(this.props.capsules.capsulesOfGroup[0].content[i]);
                            }
                        }
                    }
                }

                this.props.setCapsulesToShow(listToShow, 'home 451');

                if (this.props.isExploreGroup) {
                    if (this.state.privateCapsules.add.length === 0 && this.state.privateCapsules.remove.length === 0) {
                        this.props.dispatch(capsuleActions.setUserCapsulesForExploreGroup(this.props.groupToEdit.id, cleanedGroup));
                    } else if (this.state.privateCapsules.add.length > 0 && this.state.privateCapsules.remove.length === 0) {
                        this.setState({isPrivateCapsules: true});
                    } else if (this.state.privateCapsules.add.length === 0 && this.state.privateCapsules.remove.length > 0) {
                        this.setState({isPublicCapsules: true});
                    } else {
                        let privateCapsules = {...this.state.privateCapsules};
                        privateCapsules.remove = [];
                        this.setState({isPrivateCapsules: true, privateCapsules});
                    }

                } else {
                    this.props.dispatch(capsuleActions.editGroupCapsules(this.props.groupToEdit.id, cleanedGroup));
                    this.setState({
                        originalCapsules: {add: [], remove: []},
                    });
                }
            }
            this.props.changeGroupName(this.props.groupToEdit.name, 'home 473');
            this.setState({isCreate: false});
        }
    }

    openRemoveFromGroup = (capsuleId, userId) => {
        this.setState({removeFromGroup: true, toRemoveFromGroup: {capsuleId, userId}});
    }

    openRemoveSharedGroupFromSharedWithMe = (groupId, userId) => {
        this.setState({removeGroupFromSharedWithMe: true, toRemoveGroupFromSharedWithMe: {groupId, userId}});
    }

    deleteFromSharedGroup = () => {
        this.props.dispatch(capsuleActions.removeCapsuleFromShared(this.state.toRemoveFromGroup.capsuleId, this.state.toRemoveFromGroup.userId));
    }

    deleteGroupFromSharedWithMe = () => {

        let removeGroupId = this.state.toRemoveGroupFromSharedWithMe.groupId.id;

        this.props.dispatch(capsuleActions.removeGroupFromSharedWithMe(removeGroupId));
    }

    calculateSearchResult = () => {
        let capsuleResult = [];
        let groupResult = [];
        let groupCapsuleResult = [];
        let sharedCapsuleResult = [];

        if (isObjectExists(this.props, 'capsules.groups') && this.props.capsules.groups.length > 0) {
            for (let i in this.props.capsules.groups) {
                if (this.props.capsules.groups[i].name.toLowerCase().indexOf(this.props.searchTerm.toLowerCase()) !== -1) {
                    groupResult.push(this.props.capsules.groups[i]);
                }
            }
        }
        switch (this.props.groupView) {
            case 'groupCapsuleList':
                if (this.props.isExploreGroup) {
                    let groupId = this.props.groupToEdit.id ? this.props.groupToEdit.id : 'all';
                    this.props.dispatch(capsuleActions.searchCapsulesByName(this.props.searchTerm, groupId, 100, 0));
                } else if (isObjectExists(this.props, 'capsules.capsulesOfGroup') && this.props.capsules.capsulesOfGroup[0].content.length > 0) {
                    for (let i in this.props.capsules.capsulesOfGroup[0].content) {
                        if (this.props.capsules.capsulesOfGroup[0].content[i].name && this.props.capsules.capsulesOfGroup[0].content[i].name.toLowerCase().indexOf(this.props.searchTerm.toLowerCase()) !== -1) {
                            groupCapsuleResult.push(this.props.capsules.capsulesOfGroup[0].content[i]);
                        }
                    }
                }
                break;
            case 'userCapsuleList':
                this.props.dispatch(capsuleActions.searchCapsulesByName(this.props.searchTerm, false, 100, 0, 'own'));
                break;
            case 'sharedCapsuleList':
                this.props.dispatch(capsuleActions.searchCapsulesByName(this.props.searchTerm, false, 100, 0, 'shared'));
                break;
        }

        this.setState({groupResults: groupResult, capsuleResults: capsuleResult, groupCapsuleResult: groupCapsuleResult, sharedCapsuleResult: sharedCapsuleResult});

    }

    increaseOffset = () => {
        this.setState({offsetValue: this.state.offsetValue+100});
    }

    onDelete = (id) => {
        this.props.dispatch(capsuleActions.deleteGroup(id));
        //this.props.changeGroupView('main');  //Removed since did not remove deleted group from main LIST_TO_RESULTS_SUCCESS
        this.backToGroups();    //Added so tha a refresh occurred
    }

    afterSlide = (slideIndex) => {
        this.setState({ slideIndex: slideIndex });

    }

    cleanGroupCapsules = () => {
        let cleanedObject = {add: [], remove: []};
        let groupCapsuleIds;
        for (let i in this.props.capsules.groups) {
            if (this.props.capsules.groups[i].id === this.props.groupToEdit.id) {
                groupCapsuleIds = this.props.capsules.groups[i].capsuleIds;
                break;
            }
        }

        const modificationObject = this.state.originalCapsules;

        for (let i in modificationObject.add) {
            if ((!groupCapsuleIds || groupCapsuleIds.length === 0)  && !cleanedObject.add.includes(modificationObject.add[i])) {
                cleanedObject.add.push(modificationObject.add[i]);
            } else {
                for ( let j in groupCapsuleIds) {
                    if (!groupCapsuleIds[j].includes(modificationObject.add[i]) && !cleanedObject.add.includes(modificationObject.add[i])) {
                        cleanedObject.add.push(modificationObject.add[i]);
                    }
                }
            }

        }

        for (let i in modificationObject.remove) {
            for ( let j in groupCapsuleIds) {
                if (groupCapsuleIds[j] === modificationObject.remove[i] && !cleanedObject.remove.includes(modificationObject.remove[i])) {
                    cleanedObject.remove.push(modificationObject.remove[i]);
                }
            }
        }

        return cleanedObject;
    }

    setSearchTerm = (value) => {
        this.props.changeSearchTerm(value);
    }

    backToGroups = (isFromExplore) => {
        if (this.props.groupHistory.length > 0) {
            this.getCapsulesOfGroup(this.props.getLatestFromGroupHistory(), true);
            this.setState({getMoreCapsules: true});
        } else if (isFromExplore && this.props.typeOfList !== 'topLevelSearch' && !this.props.fromHome) {
            let newView = 'groupCapsuleList';

            if (this.props.typeOfList === 'user') {
                newView = 'userCapsuleList';
            } else if (this.props.typeOfList === 'shared') {
                newView = 'sharedCapsuleList';
            }

            this.props.changeGroupView(newView);
            this.setState({
                preventListReload: false,
                searchItemsSet:    false,
                getMoreCapsules:   true
            });
        } else {

            /*if (this.props.user.token && localStorage.getItem('MUUserLogin')) {
                this.props.dispatch(userActions.getCapsules());
            }*/

            this.props.resetGroupHistory();

            this.setState({
                originalCapsules: {
                    add:    [],
                    remove: []
                },
                capsulesOfNewGroup: [],
                addedCapsuleStates: {},
                groupName:          '',
                capsulesToAdd:      [],
                preventListReload:  false,
                searchItemsSet:     false,
                getMoreCapsules:    true,
                offsetValue:        0
            });
            this.props.setGroupToEdit({});

            if (this.props.typeOfList === 'topLevelSearch') {
                this.props.dispatch(capsuleActions.searchCapsulesByName(this.props.searchTerm, false, 100, 0));
            } else {
                this.props.changeShowSearch(false);
                this.props.changeIsSearchView(false);
                this.props.changeSearchTerm('');
                this.props.changeTypeOfList('');
            }

            if (!this.props.user.publicPage) {
                this.props.dispatch(capsuleActions.clearGroupCapsules());
                this.props.dispatch(capsuleActions.clearCapsules());
                this.props.dispatch(configActions.getChatbotConfig());
                this.props.dispatch(capsuleActions.getExploreGroups());
                this.props.dispatch(capsuleActions.getCapsuleGroups());
                this.props.dispatch(capsuleActions.getFeaturedCapsules());

            }

            if (localStorage.getItem('MUUserLogin') && !this.props.user.publicPage) {
                this.props.dispatch(userActions.getCapsules());
                this.props.dispatch(capsuleActions.getSharedCapsules(100));
                this.props.dispatch(userActions.getMostRecentCount());
                this.props.dispatch(userActions.getUserCapsuleCount());
                this.props.dispatch(capsuleActions.getFavourites());

            }
            this.props.changeGroupView('main');
            this.props.changeGroupName('', 'home 661');
            this.props.setViewedGroup('', 'home 662');
            this.props.setIsExploreGroup(false);
        }
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }

    getCapsulesOfGroup = (group, isExplore, isSubgroup) => {

        let capsules = [];
        if (group && isExplore ) {
            if (isSubgroup) {
                this.props.addToGroupHistory(this.props.groupToEdit);
            }

            this.props.dispatch(capsuleActions.clearGroupCapsules());
            if (group === 'public') {
                this.props.dispatch(capsuleActions.getCapsulesOfExploreGroup('all', 100));
                capsules = this.getGroupCapsules(this.props.capsules.capsulesByGroup, 'all');
                this.props.setIsExploreGroup('all');
                this.props.changeGroupName(this.mostRecent, 'home 682');
            } else {
                this.props.dispatch(capsuleActions.getCapsulesOfExploreGroup(group.id, 100));
                capsules = this.getGroupCapsules(this.props.capsules.capsulesByGroup, group.id);
                this.props.setViewedGroup(group.id, 'home 686');
                this.props.setIsExploreGroup(true);
                this.props.changeGroupName(group.name, 'home 688');
            }

            if (this.props.isExploreGroupView) {
                this.props.setIsExploreGroupView(false);
            }

            this.props.setGroupToEdit(group);
            this.setState({capsulesToAdd: []});
        } else if (group) {
            !this.props.user.publicPage && this.props.dispatch(capsuleActions.getCapsulesByGroup(group.id));
            capsules = this.getGroupCapsules(this.props.user.userGroupCapsules, group.id);
            this.props.setViewedGroup(group.id, 'home 700');
            this.props.setIsExploreGroup(false);
            this.props.setGroupToEdit(group);
            this.props.changeGroupName(group.name, 'home 703');
        }

        this.props.dispatch(configActions.getChatbotConfig());

        this.props.changeShowSearch(false);
        this.props.changeIsSearchView(false);
        this.props.changeSearchTerm('');
        this.props.setCapsulesToShow(capsules, 'home 711');
        this.props.changeGroupView('groupCapsuleList', 'home 714');
        this.setState({capsuleStates: {}});
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }

    openCapsuleListFromHome = (i, capsuleList, groupName, id) => {
        let listToShow = capsuleList;

        if (id) {

            listToShow = this.getGroupCapsules(this.props.user.userGroupCapsules, id);
            this.props.setViewedGroup(id, 'home 724');
            this.props.setIsExploreGroup(false);
        } else {
            this.props.setIsExploreGroup(true);
        }

        //this.props.dispatch(capsuleActions.userCapsulesToCapsuleList(listToShow));
        this.props.setFromHome(true);
        this.props.setCapsulesToShow(listToShow, 'home 732');
        this.props.changeGroupName(groupName, 'home 733');
        this.props.changeStartingIndex(i);
        this.props.changeGroupView('capsuleList');

    }

    openCapsuleList = (i, group, isSearch, id, isTopLevel) => {
        let indexToOpen = i;
        let arrayToSearchIn =  this.props.capsules.capsule;
        if (group && !isSearch && id) {
            arrayToSearchIn = this.getGroupCapsules(this.props.user.userGroupCapsules, id);
        } else if (isSearch) {
            if (isTopLevel) {
                arrayToSearchIn = mergeSearchResults(this.props.capsules.searchResults);
                if (!id && this.props.typeOfList === 'topLevelSearch') {
                    indexToOpen = parseInt(i) + this.props.capsules.searchResults.userCapsules.length + this.props.capsules.searchResults.sharedCapsules.length;
                }

                this.props.changeGroupName(this.mainGroup, 'home 751');
                this.props.dispatch(capsuleActions.addListToSearchResults(arrayToSearchIn));
            } else {
                if (group && !this.props.isExploreGroup) {
                    arrayToSearchIn =this.state.groupCapsuleResult;
                    this.props.dispatch(capsuleActions.addListToSearchResults(arrayToSearchIn));
                    this.props.changeTypeOfList('userGroup');
                } else {
                    arrayToSearchIn = this.props.capsules.searchResults;
                }

            }

        }

        if (this.props.groupHistory.length > 0) {
            this.props.addToGroupHistory(this.props.groupToEdit);
        }

        if (id) {
            indexToOpen = this.props.getIndexOfNewCapsule(id, arrayToSearchIn);
        }

        /*if (!arrayToSearchIn[indexToOpen].id && (this.props.user.id === this.preloadUserOne || this.props.user.id === this.preloadUserThree) &&
            ((!isObjectExists(arrayToSearchIn[indexToOpen], 'externalSources.Tivo') && isObjectExists(arrayToSearchIn[indexToOpen], 'externalSources.ElasticSearch.id')) ||
        (isObjectExists(arrayToSearchIn[indexToOpen], 'externalSources.Tivo') &&
        arrayToSearchIn[indexToOpen].externalSources.Tivo.skip !== true &&
        arrayToSearchIn[indexToOpen].externalSources.Tivo.manual !== true &&
        (arrayToSearchIn[indexToOpen].externalSources.Tivo.id === undefined ||
        arrayToSearchIn[indexToOpen].externalSources.Tivo.id === '' ||
        arrayToSearchIn[indexToOpen].externalSources.Tivo.id === 0)) &&
        isObjectExists(arrayToSearchIn[indexToOpen], 'externalSources.ElasticSearch.id'))) {
            this.props.dispatch(capsuleActions.preloadSingleCapsule(arrayToSearchIn[indexToOpen].externalSources.ElasticSearch.id));
        }*/
        this.props.dispatch(capsuleActions.userCapsulesToCapsuleList(arrayToSearchIn));
        this.setState({capsules: arrayToSearchIn});
        this.props.setFromHome(false);
        this.props.changeStartingIndex(indexToOpen);
        this.props.changeGroupView('capsuleList');
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }

    getSharedCapsules = () => {
        this.props.dispatch(capsuleActions.getSharedCapsules(100));
        this.props.changeGroupView('sharedCapsuleList');
        this.props.changeGroupName(this.sharedGroup, 'home 797');
        this.props.changeTypeOfList('shared');
        this.props.changeShowSearch(false);
        this.props.setCapsulesToShow(this.props.capsules.sharedCapsules, 'home 800');
        this.setState({offsetValue: 0});
    }

    getMyCapsules = () => {
        this.props.dispatch(userActions.getCapsules());
        this.props.changeGroupView('userCapsuleList');
        this.props.changeGroupName(this.mainGroup, 'home 807');
        this.props.changeTypeOfList('user');
        this.props.changeShowSearch(false);
        this.props.setCapsulesToShow(this.props.user.userCapsules, 'home 810');
        this.setState({offsetValue: 0});
    }

    openAddGroup = () => {
        this.setState({isCreate: true});
        this.props.changeGroupView('createGroup', 'home 818');
    }

    copyGroupShareLink = () => {
        let url = process.env.REACT_APP_BASE_URI + '/group/?group=' +  this.state.groupToShare + '&share_token=' + this.props.user.groupShareToken;

        var textArea = parent.document.createElement('textarea');
        textArea.value = url;

        document.body.appendChild(textArea);
        let range = document.createRange();
        range.selectNodeContents(textArea);
        let selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
        textArea.setSelectionRange(0, 500);
        //textArea.focus();
        //textArea.select();

        try {
            let successful = document.execCommand('copy');
            this.setState({copied: successful, groupShareReady: false});
        } catch (err) {
            // eslint-disable-next-line no-console
            console.log(err);
        }

        //document.body.removeChild(textArea);
    }

    copyIsDone = (successful) => {
        this.setState({copied: successful, groupShareReady: false});
    }

    shareGroup = (e, group) => {
        e.stopPropagation();
        this.setState({groupToShare: group.id});
        this.props.dispatch(userActions.getGroupShareToken(group.id));
    }

    getMostRecentCount = () => {
        let count = '';

        if (isObjectExists(this.props, 'user.mostRecentCount.count') && this.props.user.mostRecentCount.count >= 0) {
            count = this.props.user.mostRecentCount.count;
        }

        return count;
    }

    getSharedCapsuleCount = () => {
        let count = '';

        //if (isObjectExists(this.props, 'user.userCapsuleCount.shared') && this.props.user.userCapsuleCount.shared) {
        if (isObjectExists(this.props, 'user.userCapsuleCount.shared') && this.props.user.userCapsuleCount.shared >= 0) {
            count = this.props.user.userCapsuleCount.shared;
        }

        return count;
    }

    onNameChange = (e) => {
        let newGroup = {...this.props.groupToEdit};
        newGroup.name = e.target.value;
        this.props.setGroupToEdit(newGroup);
    }

    onChange = (e, id) => {

        let originalCapsules = this.state.originalCapsules;
        let capsuleStates = this.state.capsuleStates;
        let addedCapsuleStates = this.state.addedCapsuleStates;
        let capsulesChanged = this.state.capsulesChanged;
        let capsulesInCarousel = this.state.capsulesInCarousel;

        let index, capsInd;
        if (e === false) {
            index = originalCapsules.add.indexOf(id);
            capsInd = capsulesInCarousel.findIndex(x => x.id === id);

            capsulesInCarousel.splice(capsInd, 1);
            if (index > -1) {
                originalCapsules.add.splice(index, 1);
            }
            originalCapsules.remove.push(id);
        } else {

            index = originalCapsules.remove.indexOf(id);
            if (index > -1) {
                originalCapsules.remove.splice(index, 1);
            }
            originalCapsules.add.push(id);
            for (let j in this.props.user.userCapsules) {
                if (this.props.user.userCapsules[j].id === id) {
                    capsulesInCarousel.push(this.props.user.userCapsules[j]);
                    break;
                }
            }

        }

        if (this.props.isExploreGroup === true) {
            this.findPrivateCapsules();
        }

        capsuleStates[id] = !capsuleStates[id];

        if (!this.state.capsulesChanged) {
            capsulesChanged = true;
        }

        this.setState({originalCapsules, capsuleStates, capsulesChanged, addedCapsuleStates, capsulesInCarousel});
    }

    onExploreChange = (isAdd, id) => {
        let capsulesToAdd = [...this.state.capsulesToAdd];
        let capsulesInCarousel = [...this.state.capsulesInCarousel];
        let capsInd;
        if (isAdd) {
            capsulesToAdd.push(id);

            for (let j in this.props.user.userCapsules) {
                if (this.props.user.userCapsules[j].id === id) {
                    capsulesInCarousel.push(this.props.user.userCapsules[j]);
                    break;
                }
            }

        } else {
            for (let i in capsulesToAdd) {
                if (capsulesToAdd[i] === id) {
                    capsulesToAdd.splice(i, 1);
                }
            }
            capsInd = capsulesInCarousel.findIndex(x => x.id === id);
            capsulesInCarousel.splice(capsInd, 1);

        }

        this.setState({capsulesToAdd, capsulesInCarousel});
    }

    onCreateChange = (e, id) => {
        let capsulesOfNewGroup = this.state.capsulesOfNewGroup;
        let addedCapsuleStates = this.state.addedCapsuleStates;
        let index;

        if (e === true) {
            capsulesOfNewGroup.push(id);
        } else {
            index = capsulesOfNewGroup.indexOf(id);
            if (index > -1) {
                capsulesOfNewGroup.splice(index, 1);
            }

        }

        addedCapsuleStates[id] = !addedCapsuleStates[id];
        this.setState({capsulesOfNewGroup, addedCapsuleStates});

    }

    capsuleContainedInGroup = (capsuleId) => {
        let result = false;
        let groupCapsules = this.props.capsules.capsulesOfGroup.length > 0 ? this.props.capsules.capsulesOfGroup[0].content : [];
        let capsuleStates = this.state.capsuleStates;
        for (let i in groupCapsules) {
            if (groupCapsules[i].id === capsuleId) {
                result = true;
                break;
            }
        }
        if (capsuleStates[capsuleId] === undefined || capsuleStates[capsuleId] !== result) {
            capsuleStates[capsuleId] = result;
            this.setState({capsuleStates});
        }

        return result;
    }

    topLevelSearch = (value) => {
        //this.props.dispatch(capsuleActions.clearCapsules());
        this.props.dispatch(capsuleActions.searchCapsulesByName(value, false, 100, 0));
        this.props.changeTypeOfList('topLevelSearch');
        this.props.changeSearchTerm(value);
    }

    //addCapsuleToGroup called when adding capsule from gallery view, after update capsuleAddedToGroup bool changes
    //only called here from any module but profile is only function that looks for capsuleAddedToGroup
    addCapsuleToGroup = (groupId, id) => {
        this.props.dispatch(capsuleActions.addCapsuleToGroup(groupId, id));
    }

    removeCapsuleFromGroup = (groupId, id) => {
        this.props.dispatch(capsuleActions.removeCapsuleFromGroup(groupId, id));
    }

    setCapsulesStates = () => {
        //let groupCapsules, capsules, allCapsules, uniqCapsules = [];    //*see below
        //let capsules, allCapsules, uniqCapsules = [];
        let capsules, uniqCapsules = [];
        //if (this.props.capsules && this.props.capsules.capsulesOfGroup && 0 < this.props.capsules.capsulesOfGroup.length) {
        if (this.props.capsuleList) {
            //groupCapsules = this.props.capsules.capsulesOfGroup[0].content;  //*5-12-22 commented out all groupCapsules because not concatingating any more and capsules wasn't being set so passed by next section causing capsules = [] when it shouldn't be
            //capsules = this.props.capsules.capsulesOfGroup[0].content;
            capsules = this.props.capsuleList;
            if (this.props.user.capsuleDeleted === true && this.props.groupName === this.mainGroup) {
                //groupCapsules = this.props.user.userCapsules;   // added line below capsules= due to other comments in this section but need to go back and verify after capsule delete this mod works for this condition
                capsules = this.props.user.userCapsules;
                this.props.dispatch(capsuleActions.getFavourites());        //lds this fixed issue but when reviewing all dispatch calls for capsules, might not be best place for this.
                this.props.dispatch(capsuleActions.getFeaturedCapsules());  //same comment
            }
        }

        if (this.props.user && this.props.user.userCapsules && 0 < this.props.user.userCapsules.length) {
            capsules = this.props.capsuleList || [];
        }

        //if (groupCapsules && capsules) {  //* see above
        //if (capsules) {
        //    //was sorted
        //    //allCapsules = sortAlphabetically(groupCapsules.concat(capsules));
        //    if (this.props.groupName !== this.mostRecent) {
        //        //allCapsules = sortAlphabetically(groupCapsules.concat(capsules));   //combo of * and also removing concat - think old from original explore design plus was causing issue in featured or favorites hopefully gone now wo issue
        //        allCapsules = sortAlphabetically(capsules);
        //    } else {
        //        //allCapsules = groupCapsules.concat(capsules);   //*see above
        //        allCapsules = sortByUpdatedDate(capsules);
        //    }
        //    uniqCapsules = allCapsules.filter((allCapsules, index, self) =>
        //        index === self.findIndex((t) => (
        //            t.id === allCapsules.id
        //        ))
        //    );
        //} else {
        //    uniqCapsules = capsules;
        //}

        uniqCapsules = capsules;
        if (this.props.groupName !== this.mostRecent) {
            capsules = sortAlphabetically(capsules);
        } else {
            capsules = sortByUpdatedDate(capsules);
        }

        for (let i in uniqCapsules) {
            this.capsuleContainedInGroup(uniqCapsules[i].id);
        }
        this.props.setCapsulesToShow(capsules, 'home 1064');
        this.setState({uniqCapsules});
    }

    renderCapsulesOfGroup = () => {
        let capsulesToList = [];
        let uniqCapsules = this.state.uniqCapsules ? this.state.uniqCapsules : this.props.user.userCapsules;

        if (this.state.groupIndex !== 0) {
            this.setState({groupIndex: 0});
        }

        uniqCapsules = this.props.user.userCapsules;

        capsulesToList = Object.keys(uniqCapsules).map((i) => (
            this.getListElement(i, uniqCapsules, true, false, this.onChange, this.state.capsuleStates)

        ));

        return (
            <Container className="">

                <Row className="capsule-header group-element">
                    {!this.props.isExploreGroup &&
                    <Col>
                        <input type="text" value={this.props.groupToEdit.name} onChange={(e) => this.onNameChange(e)} />
                    </Col>
                    }
                    {/*}<div>{this.props.groupToEdit.name}</div>*/}
                </Row>
                {capsulesToList}

            </Container>
        );

    }

    renderCapsules = (type) => {
        let capsules;
        let isGroupList = false;
        let subgroupsToList;
        let subgroups = this.props.groupToEdit.child ? this.props.groupToEdit.child : [];

        switch (type) {
            case 'sharedCapsuleList':
                capsules = this.props.capsules.sharedCapsules;
                break;
            case 'userCapsuleList':
                capsules = this.props.user.userCapsules;
                if (this.state.fixSearch) {
                    this.props.setCapsulesToShow(capsules, 'home 1114');
                    this.setState({fixSearch: false});
                }
                break;
            case 'groupCapsuleList':    //review where values updated in attila's updates.
                if (this.isOwnerGroup() === 'shared') {  //shared group
                    capsules = this.props.capsules.loading ? [] : this.props.capsules.capsulesOfGroup.length > 0 ? this.props.capsules.capsulesOfGroup[0].content : [];
                } else if (this.props.isExploreGroup) {
                    capsules = this.props.capsuleList || [];
                } else {
                    capsules = this.props.capsules.loading ? [] : sortAlphabetically(this.getGroupCurrentCapsules());
                }

                isGroupList = true;
                if (this.state.fixSearch) {
                    this.props.setCapsulesToShow(capsules, 'home 1133');
                    this.setState({fixSearch: false});
                }
                break;
        }

        let capsulesToList = Object.keys(capsules).map((i) => (
            this.getListElement(i, capsules, 'capsules', isGroupList)

        ));

        if (subgroups && subgroups.length > 0) {
            subgroupsToList = (
                <React.Fragment>
                    { Object.keys(subgroups).map((i) => (
                        <Row key={i} className="group-element hover" >
                            <Col xs="10" sm="10" md="10" lg="10" onClick={() => this.getCapsulesOfGroup(subgroups[i], true, true)}>
                                {subgroups[i].name}<img src={backButton} className="subgroup-arrow ml-1" />
                            </Col>

                            <Col xs="2" sm="2" md="2" lg="2" className="right">
                                {this.getCount(subgroups[i].id)}
                            </Col>
                        </Row>
                    ))}
                </React.Fragment>
            );
        }

        return (

            <Container className="group-list">
                {subgroupsToList}
                <div className="pb-2"></div>
                {capsulesToList}
                {this.props.capsules.loading &&
                <Row>
                    <Col>

                        <div className='spinner loading'>
                            <ClipLoader
                                css={this.override}
                                sizeUnit={'px'}
                                size={25}
                                color={'#123abc'}
                                loading={true}
                            />
                        </div>
                    </Col>

                </Row>
                }
                {type === 'userCapsuleList' && !this.props.isExploreGroup && localStorage.getItem('MUUserLogin')  ? this.renderOptions(false, true) : null}
                {type === 'groupCapsuleList' && !this.props.isExploreGroup && localStorage.getItem('MUUserLogin') && this.isOwnerGroup() === 'owner' ? this.renderOptions(true) : null}
            </Container>
        );
    }

    isOwnerGroup = () => {
        let groups = this.props.capsules.groups;
        let result = 'neither';
        for (let i in groups) {
            if (groups[i].id === this.props.groupToEdit.id) {
                if (groups[i].shared === true) {
                    result = 'shared';
                } else {
                    result = 'owner';
                }
                break;
            }
        }

        return result;
    }

    getGroupCurrentCapsules = (isExploreGroup) => {

        let result = [];
        if (!isExploreGroup) {
            if (this.props.user.userGroupCapsules && this.props.user.userGroupCapsules !== undefined && this.props.user.userGroupCapsules.length > 0) {
                for (let i in this.props.user.userGroupCapsules) {
                    if (this.props.viewedGroup === this.props.user.userGroupCapsules[i].id) {
                        result = this.props.user.userGroupCapsules[i].content;
                    }
                }
            }
        } else {
            result = this.props.capsuleList;
        }

        return result;
    }

    renderCreateGroup = () => {
        let userCapsules = this.props.user.userCapsules;

        let capsulesToList = Object.keys(userCapsules).map((i) => (
            this.getListElement(i, userCapsules, true, false, this.onCreateChange, this.state.addedCapsuleStates)

        ));

        return (
            <Container className="group-list">
                <Row className="capsule-header group-element">
                    <Col>
                        <input type="text" placeholder="Type a Group Name" value={this.props.groupToEdit.name} onChange={(e) => this.onNameChange(e)} />
                    </Col>
                </Row>
                {capsulesToList}
            </Container>
        );
    }

    renderCircle = (capsules, onCheckChange, i, statesHolder) => {
        let circle;

        circle = (
            <Col className="right right-grouplist2">
                {
                    statesHolder[capsules[i].id] ?
                        <span onClick={() => onCheckChange(false, capsules[i].id)} className="circle-filled right" />
                        :
                        <span onClick={() => onCheckChange(true, capsules[i].id)} className="circle right" />
                }
            </Col>
        );

        return circle;
    }

    renderGroupElement = (isGroups, isGroupList, capsules, i, onCheckChange, statesHolder) => {
        return (
            <Row className="group-element hover" key={i}>
                {isGroups === 'capsules' ?
                    <React.Fragment>

                        {this.props.groupView === 'sharedCapsuleList' ?
                            <React.Fragment>
                                <Col xs="10" sm="10" md="10" lg="10" onClick={isGroupList ? () => this.openCapsuleList(i, true) : () => this.openCapsuleList(i)}>
                                    {this.props.getThumbnailPicture(capsules[i], true)}

                                    <div>{getCapsuleName(capsules[i])}</div>
                                    {
                                        capsules[i].ownerId ?
                                            isObjectExists(capsules[i], 'items.contact.organisation.organizationName') && capsules[i].items.contact.organisation.organizationName !== '' && <div><span className="category">{capsules[i].items.contact.organisation.organizationName}</span></div>
                                            :
                                            capsules[i].category !== '' && <div><span className="category">{capsules[i].category}</span></div>
                                    }
                                </Col>
                                <Col xs="2" sm="2" md="2" lg="2" className="my-auto right" onClick={() => this.openRemoveFromGroup(capsules[i].id, capsules[i].ownerId)}>
                                    <img className="trash-icon" src={deleteIcon} />
                                </Col>
                            </React.Fragment>
                            :
                            <React.Fragment>
                                <Col xs="11" sm="11" md="11" lg="11" onClick={isGroupList ? () => this.openCapsuleList(i, true) : () => this.openCapsuleList(i)}>
                                    {this.props.getThumbnailPicture(capsules[i], true)}

                                    <div>{getCapsuleName(capsules[i])}</div>
                                    {
                                        capsules[i].ownerId ?
                                            isObjectExists(capsules[i], 'items.contact.organisation.organizationName') && capsules[i].items.contact.organisation.organizationName !== '' && <div><span className="category">{capsules[i].items.contact.organisation.organizationName}</span></div>
                                            :
                                            capsules[i].category !== '' && <div><span className="category">{capsules[i].category}</span></div>
                                    }
                                </Col>
                            </React.Fragment>
                        }
                    </React.Fragment>
                    :
                    <Col>
                        {getCapsuleName(capsules[i])}
                    </Col>
                }

                {isGroups === true &&
                            this.renderCircle(capsules, onCheckChange, i, statesHolder)
                }
            </Row>
        );
    }

    getListElement = (i, capsules, isGroups, isGroupList, onCheckChange, statesHolder) => {

        if (i == '0' || getCapsuleName(capsules[i]).charAt(0).toLowerCase() !== getCapsuleName(capsules[i-1]).charAt(0).toLowerCase()) {
            return (
                <React.Fragment key={i}>
                    {this.props.groupName !== this.mostRecent &&
                    <Row  className="group-element letter-line">
                        <Col>{getCapsuleName(capsules[i]).charAt(0).toUpperCase()}</Col>
                    </Row>
                    }
                    {this.renderGroupElement(isGroups, isGroupList, capsules, i, onCheckChange, statesHolder)}
                </React.Fragment>
            );
        } else {
            return this.renderGroupElement(isGroups, isGroupList, capsules, i, onCheckChange, statesHolder);
        }

    }

    getProfilePicture = () => {
        if (isObjectExists(this.props, 'capsules.mainCapsule.items.contact.image.thumbnail.metadata.storageUUID') && localStorage.getItem('MUUserLogin')) {
            return getUrlAndLicence(this.props.capsules.mainCapsule.items.contact.image.thumbnail.metadata.storageUUID, false, '100x100,crop', 'VieroToolkitUtilsTypeStillSingle').url;
        } else {
            return profileIcon;
        }
    }

    getPublicProfilePicture = (capsule) => {

        if (isObjectExists(capsule, 'publicUser.image')) {
            return getUrlAndLicence(capsule.publicUser.image, false, '100x100,crop', 'VieroToolkitUtilsTypeStillSingle').url;
        } else {
            return defaultProfilePicture;
        }

    }

    getPublicProfileButton = (capsule) => {
        if (this.shouldShowPublicProfileButton(capsule)) {
            return (
                <img src={this.getPublicProfilePicture(capsule)}
                    className={'profile-icon hover public-button'}
                    onClick={(e) => {e.stopPropagation(); location.replace('/' + capsule.publicUser.slug);}}>

                </img>);
        } else {
            return null;
        }
    }

    shouldShowPublicProfileButton = (capsule) => {
        if (isObjectExists(capsule, 'publicUser.image') && capsule.publicUser.image !== '' && (!this.props.user.publicPage && (capsule.public && (capsule.groups.length > 0 || this.isInFavorites(capsule.id))))) {
            if (localStorage.getItem('MUUserLogin') && this.props.user.id !== capsule.ownerId && this.isInFavorites(capsule.id)) {
                return false;
            }
            return true;
        }
        return false;
    }

    renderHeader = () => {
        const view = this.props.groupView;
        if (this.state.isUserGroupView) {
            return null;
        } else {

            switch (view) {
                case 'main':
                case 'search':
                    return (
                        <React.Fragment>
                            <Row className="header mb-auto my-auto">
                                <Col className="my-auto" xs="2" sm="2" md="2" lg="2">
                                    <span onClick={() => {window.location.href = process.env.REACT_APP_BASE_URI; location.reload();}} className="title-line hover">{window.innerWidth > 768 ?  'MY UNIVERSE' : 'MYU'}</span>
                                </Col>
                                {this.renderSearch()}

                                {!this.props.showSearch &&
                            <React.Fragment>
                                <Col className="my-auto right" xs="2" sm="1" md="1" lg="1">
                                    {localStorage.getItem('MUUserLogin') && !this.props.user.publicPage &&
                            <div className="navigation-button my-auto" onClick={() => this.setState({isUserGroupView: !this.state.isUserGroupView})}>{this.setGroups}</div>
                                    }
                                </Col>
                                <Col className="my-auto right" xs="2" sm="2" md="1" lg="1">
                                    <div className="navigation-button my-auto" onClick={() => this.setState({isGalleryView: !this.state.isGalleryView})}>{this.state.isGalleryView ? 'List' : 'Gallery'}</div>
                                </Col>
                                <Col className="my-auto right" xs="2" sm="2" md="2" lg="2">
                                    <img className="profile-icon hover" onClick={localStorage.getItem('MUUserLogin') ? () => this.props.changeGroupView('profileSettings') : () => this.props.navigateTo('login')}
                                        src={this.getProfilePicture()} />
                                </Col>
                            </React.Fragment>
                                }
                            </Row>
                        </React.Fragment>
                    );
                case 'createGroup':
                case 'editGroup':
                    return <Row className="header mb-auto my-auto">
                        <Col className="p-2 left my-auto" >
                            <span
                                onClick={view === 'createGroup' ? () => { this.props.changeGroupView('main'); this.setState({groupName: ''}); this.props.setCapsulesToShow('', 'home 1410');}
                                    : () => this.props.changeGroupView('groupCapsuleList', 'home 1420')}
                                className="hover"><img src={backButton} className="back-button"/> <span className="navigation-button">Groups</span></span>
                        </Col>

                        <Col className="p-2 right my-auto" ><span className="navigation-button hover" onClick={() => this.onSave()}>Save</span></Col>
                    </Row>;
                case 'revokeSharedGroupUsers':  //verify needed
                    return (
                        null
                    );
                case 'sharedCapsuleList':
                case 'userCapsuleList':
                case 'groupCapsuleList':
                    return (
                        <Row className="header mb-auto">
                            {!this.props.showSearch &&
                            <Col xs="5" sm="4" md="4" lg="4" className="my-auto">
                                <img src={backButton} className="back-button hover" onClick={() => this.backToGroups()}/>
                                <span className="navigation-button" onClick={() => this.backToGroups()}>Groups</span>
                            </Col>
                            }
                            {this.renderSearch()}

                        </Row>
                    );
                case 'profileSettings':
                    return <Row className="header mb-auto">
                        <Col className="my-auto"><span className="navigation-button hover" onClick={() => this.backToGroups()}>Back</span></Col>
                        <Col className="text-center my-auto">Profile</Col>
                        <Col></Col>
                    </Row>;
            }
        }
    }

    getGroupCapsules = (capsules, id) => {
        let capsuleList = [];

        for (let i in capsules) {
            if (capsules[i].id === id) {
                return capsules[i].content;
            }
        }

        return capsuleList;
    }

    renderCustomGroupsList = () => {
        let view = '';
        let renderedGroups = null;
        if (this.props.capsules && this.props.capsules.groups && 0 < this.props.capsules.groups.length) {

            const groups = this.props.capsules.groups;
            renderedGroups = (
                Object.keys(groups).map((i) => (
                    <Row key={i} className="group-element hover" >
                        <Col onClick={() => this.getCapsulesOfGroup(groups[i])}>
                            {groups[i].name}
                        </Col>
                        <Col className="right">
                            {groups[i].capsuleIds && groups[i].capsuleIds.length > 0 ? groups[i].capsuleIds.length : 'Add'}
                        </Col>
                    </Row>
                ))
            );
        }

        view = (
            <React.Fragment>
                <Collapse isOpen={this.state.isShowMoreOpen}>
                    {renderedGroups}
                </Collapse>
                {/*<Row>
                    <Col className={this.state.isShowMoreOpen ? 'show-more opened hover navigation-button mb-2' : 'show-more hover navigation-button mb-2'} onClick={() => this.setState({isShowMoreOpen: !this.state.isShowMoreOpen})}>
                        {this.state.isShowMoreOpen ? this.showLess : this.showMoreGroups }
                    </Col>
                </Row>*/}
            </React.Fragment>
        );

        return view;

    }

    getGroupItemView = (groupItem) => {

        if (groupItem.id === 'my_capsules' && !this.props.user.publicPage) {
            return (
                <React.Fragment>
                    <Row onClick={() => this.getMyCapsules()} className="group-element hover pt-3">
                        <Col md="10" className="group-title">
                            {this.mainGroup}
                        </Col>
                        <Col className="right main-group-seeAll-title">
                            <span>{this.seeAll}</span>
                        </Col>
                    </Row>
                    <div>
                        <HomeItemCarousel
                            getImageFromImageAndVideos={this.props.getImageFromImageAndVideos}
                            capsules={this.props.user.userCapsules ? this.props.user.userCapsules : []}
                            onSelect={this.openCapsuleListFromHome}
                            groupName={this.mainGroup}
                            getFavoriteButton={this.getFavoriteButton}
                            isFeatured={this.state.isFeatured}
                            getPublicProfileButton={this.getPublicProfileButton}
                        ></HomeItemCarousel>
                    </div>
                </React.Fragment>
            );
        } else if (groupItem.id === 'shared_capsules' && !this.props.user.publicPage) {
            return (
                <React.Fragment>
                    <Row onClick={() => this.getSharedCapsules()} className="group-element hover pt-3">
                        <Col className="group-title">
                            {this.sharedGroup}
                        </Col>
                        <Col className="right main-group-seeAll-title">
                            <span>{this.seeAll}</span>
                        </Col>
                    </Row>
                    <div>
                        <HomeItemCarousel
                            getImageFromImageAndVideos={this.props.getImageFromImageAndVideos}
                            capsules={this.props.capsules.sharedCapsules ? this.props.capsules.sharedCapsules : []}
                            onSelect={this.openCapsuleListFromHome}
                            groupName={this.sharedGroup}
                            getFavoriteButton={this.getFavoriteButton}
                            isFeatured={this.state.isFeatured}
                            getPublicProfileButton={this.getPublicProfileButton}

                        ></HomeItemCarousel>
                    </div>
                </React.Fragment>
            );
        } else if (groupItem.id !== 'my_capsules' && groupItem.id !== 'shared_capsules' && this.props.user.userGroupCapsules) {
            this.props.setViewedGroup(groupItem.id, 'home 1551');
            return (
                <React.Fragment>
                    <Row className="group-element hover pt-3">
                        <Col className=" group-title" >
                            <span onClick={() => this.getCapsulesOfGroup(groupItem)}>{groupItem.shared ? groupItem.name + ' (shared)' : groupItem.name}</span>
                        </Col>
                        {/*<Col>
                            <span onClick={() => this.getCapsulesOfGroup(groupItem)}>{this.seeAll}</span>
                        </Col>
                        {!groupItem.shared &&*/}
                        {/*<Col className="right" onClick={() => this.getCapsulesOfGroup(groupItem)}>*/}
                        <Col className="right group-right-title" >
                            {!this.props.user.publicPage &&
                                <span onClick={groupItem.shared ? () => this.openRemoveSharedGroupFromSharedWithMe(groupItem, this.props.user.id) : (e) => this.shareGroup(e, groupItem)}>{groupItem.shared ? 'Remove share' : 'Share Group'}</span>
                            }
                        </Col>
                        {/* } */}
                    </Row>
                    <Row>
                        <Col>
                            <HomeItemCarousel
                                getImageFromImageAndVideos={this.props.getImageFromImageAndVideos}
                                capsules={this.getGroupCapsules(this.props.user.userGroupCapsules, groupItem.id)}
                                onSelect={this.openCapsuleListFromHome}
                                groupName={groupItem.name}
                                group={groupItem}
                                getFavoriteButton={this.getFavoriteButton}
                                isFeatured={this.state.isFeatured}
                                getPublicProfileButton={this.getPublicProfileButton}
                            ></HomeItemCarousel>
                        </Col>
                    </Row>
                </React.Fragment>
            );
        } else {
            return null;
        }
    }

    renderCustomGroups = () => {
        let view = '';
        let renderedGroups = null;

        if (isObjectExists(this.props, 'capsules.groups') && (this.props.user || this.props.user.publicPage)) {

            const groups = this.getGroupList(this.props.capsules.groups, true);

            renderedGroups = (
                Object.keys(groups).map((i) => (
                    <React.Fragment  key={i}>
                        {this.getGroupItemView(groups[i])}
                    </React.Fragment>
                ))
            );
        }

        view = (
            <React.Fragment>
                <div className="mt-5">
                    {renderedGroups}
                </div>
                {/*<Row>
                    <Col className={this.state.isShowMoreOpen ? 'show-more opened hover navigation-button mb-2' : 'show-more hover navigation-button mb-2'} onClick={() => this.setState({isShowMoreOpen: !this.state.isShowMoreOpen})}>
                        {this.state.isShowMoreOpen ? this.showLess : this.showMoreGroups }
                    </Col>
                </Row>*/}
            </React.Fragment>
        );

        return view;

    }

    getCount = (id) => {
        let count = 0;
        let exploreObject = this.props.capsules.exploreGroups;

        for (let i in exploreObject) {
            if (exploreObject[i].id === id) {
                count = exploreObject[i].count;
                break;
            }
        }

        if (id === 'all' && isObjectExists(this.props, 'user.mostRecentCount.count')) {
            count = this.props.user.mostRecentCount.count;
        }

        return new Intl.NumberFormat({ maximumSignificantDigits: 3 }).format(count);
    }

    isGroupInConfig = (id, isUser) => {
        let result = false;
        let config;
        if (isObjectExists(this.props, 'user.config.my_category') && isUser) {
            config = this.props.user.config.my_category;
        } else if (isObjectExists(this.props, 'user.config.explore_category')) {
            config = this.props.user.config.explore_category;
        }
        if ((this.props.user.token && localStorage.getItem('MUUserLogin') || this.props.user.publicPage) && 0 < config.length) {
            let doNotDisplay = false;
            for (let i in config) {
                if (config[i].groupId === id && config[i].display) {
                    return true;
                } else if (config[i].groupId === id && !config[i].display) {
                    doNotDisplay = true;
                    break;
                }
            }
            //refresh groups when a new public group turned on while user logged in
            if (!isUser && !doNotDisplay) {    //can only get here if is Explore groups (!isUser) and there was no group id match in user config
                return true;
            }
        }
        return result;
    }

    checkIfSomethingToDisplay = (groups) => {
        for (let i in groups) {
            if (groups[i].display) {
                return true;
            }
        }
        return false;
    }

    getGroupList = (groups, isUser) => {
        let result = [];

        if (isUser) {
            if (isObjectExists(this.props, 'user.config.my_category') && 0 < this.props.user.config.my_category.length) {
                if (this.isGroupInConfig('my_capsules', true)) {
                    result.push({id: 'my_capsules', name: this.mainGroup});
                }
                if (this.isGroupInConfig('shared_capsules', true)) {
                    result.push({id: 'shared_capsules', name: this.sharedCategory});
                }
                for (let i in groups) {
                    if (this.isGroupInConfig(groups[i].id, true)) {
                        result.push(groups[i]);
                    }
                }
                result = this.orderGroups(result, this.props.user.config.my_category, false);
            } else if (isObjectExists(this.props, 'user.config.my_category') && groups.length + 2 === this.props.user.config.my_category.length ) {
                result.push({id: 'my_capsules', name: this.mainGroup});
                result.push({id: 'shared_capsules', name: this.sharedCategory});
                for (let i in groups) {
                    result.push(groups[i]);
                }
                result = this.orderGroups(result, this.props.user.config.my_category, false);
            } else {
                result.push({id: 'my_capsules', name: this.mainGroup});
                result.push({id: 'shared_capsules', name: this.sharedCategory});
                result = result.concat(groups);
            }

        } else {
            if (isObjectExists(this.props, 'user.config.explore_category') && 0 < this.props.user.config.explore_category.length) {
                if (this.isGroupInConfig('most_recent')) {
                    result.push({id: 'all', name: this.mostRecent});
                }
                for (let i in groups) {
                    if (this.isGroupInConfig(groups[i].id, false)) {
                        result.push(groups[i]);
                    }
                }

                result = this.orderGroups(result, this.props.user.config.explore_category, true);
            } else if (isObjectExists(this.props, 'user.config.explore_category') && groups.length + 1 === this.props.user.config.explore_category.length ) {
                result.push({id: 'all', name: this.mostRecent});
                for (let i in groups) {
                    result.push(groups[i]);
                }
                result = this.orderGroups(result, this.props.user.config.explore_category, true);
            } else {
                result.push({id: 'all', name: this.mostRecent});
                result = result.concat(groups);
            }
        }

        return result;
    }

    orderGroups = (groups, config, isExploreGroup) => {
        let orderedGroups = [];
        let id = '';

        for (let i in config) {
            for (let j in groups) {
                if (config[i].groupId === 'most_recent') {
                    id = 'all';
                } else {
                    id = config[i].groupId;
                }
                if (id === groups[j].id) {
                    orderedGroups.push(groups[j]);
                }
            }
        }

        let found;  //section added to add any exploreGroups enabled or added that are not in the userConfig while they are logged in. Backend auto updates user config on next login
        if (isExploreGroup) {
            for (let i in groups) {
                found = false;
                for (let j in orderedGroups) {
                    if (groups[i].id === orderedGroups[j].id) {
                        found = true;
                    }
                }
                if (!found) {
                    orderedGroups.push(groups[i]);
                }
            }
        }

        return orderedGroups;
    }

    renderExploreGroupsList = () => {
        let view = '';

        if (isObjectExists(this.props, 'capsules.exploreGroups') && 0 < this.props.capsules.exploreGroups.length) {
            const groups = this.props.capsules.exploreGroups;
            let groupList = this.getGroupList(groups);
            if (groupList.length > 0) {
                let renderedGroups = (
                    <React.Fragment>
                        { Object.keys(groupList).map((i) => (
                            i !== '0' && i !== '1' &&
                        <Row key={i} className="group-element hover" >
                            <Col xs="8" sm="8" md="8" lg="8" onClick={() => this.getCapsulesOfGroup(groupList[i], true)}>
                                {groupList[i].name}
                            </Col>

                            <Col xs="4" sm="4" md="4" lg="4" className="right">
                                {this.getCount(groupList[i].id)}
                            </Col>
                        </Row>
                        ))}
                    </React.Fragment>
                );

                view = (
                    <React.Fragment>
                        {groupList[0].id === 'all' ?
                            <Row className="group-element hover" >
                                <Col xs="8" sm="8" md="8" lg="8" onClick={() => this.getCapsulesOfGroup('public', true)}>
                                    {this.mostRecent}
                                </Col>

                                <Col xs="4" sm="4" md="4" lg="4" onClick={() => this.getCapsulesOfGroup('public', true)} className="right">
                                    {isObjectExists(this.props, 'user.mostRecentCount.count') && this.props.user.mostRecentCount.count}
                                </Col>
                            </Row>
                            :
                            <Row className="group-element hover" >
                                <Col xs="8" sm="8" md="8" lg="8" onClick={() => this.getCapsulesOfGroup(groupList[0], true)}>
                                    {groupList[0].name}
                                </Col>

                                <Col xs="4" sm="4" md="4" lg="4" onClick={() => this.getCapsulesOfGroup(groupList[0], true)} className="right">
                                    {this.getCount(groupList[0].id)}
                                </Col>
                            </Row>
                        }
                        {groupList[1] &&
                    <Row className="group-element hover" >
                        <Col xs="8" sm="8" md="8" lg="8" onClick={() => this.getCapsulesOfGroup(groupList[1], true)}>
                            {groupList[1].name}
                        </Col>

                        <Col xs="4" sm="4" md="4" lg="4" onClick={() => this.getCapsulesOfGroup(groupList[1], true)} className="right">
                            {this.getCount(groupList[1].id)}
                        </Col>
                    </Row>
                        }

                        {renderedGroups}

                    </React.Fragment>
                );
                // 7-9-20 - add abilty to display Most Recent if all explore groups turned off.
                // Verify whether if (isObjectExists(....)) is required and whether it fails if groups are removed vs turned off
            } else if (isObjectExists(this.props, 'capsules.exploreGroups')) {

                view = (
                    <React.Fragment>
                        <Row className="group-element hover" >
                            <Col xs="8" sm="8" md="8" lg="8" onClick={() => this.getCapsulesOfGroup('public', true)}>
                                {this.mostRecent}
                            </Col>

                            <Col xs="4" sm="4" md="4" lg="4" className="right" onClick={() => this.getCapsulesOfGroup('public', true)}>
                                {isObjectExists(this.props, 'user.mostRecentCount.count') && this.props.user.mostRecentCount.count}
                                {/* 100 */}
                            </Col>
                        </Row>

                    </React.Fragment>
                );

            }
        }
        return view;

    }

    renderExploreGroups = () => {
        let view = '';

        if (isObjectExists(this.props, 'capsules.exploreGroups') && 0 < this.props.capsules.exploreGroups.length) {

            let groupList = this.getGroupList(this.props.capsules.exploreGroups);

            let renderedGroups = (
                <React.Fragment>
                    { Object.keys(groupList).map((i) => (

                        <React.Fragment key={i}>
                            <Row key={i} className="group-element hover pt-3" >
                                <Col className="group-title" xs="8" sm="8" md="8" lg="8" onClick={() => this.getCapsulesOfGroup(groupList[i], true)}>
                                    {groupList[i].name}
                                </Col>

                                <Col xs="4" sm="4" md="4" lg="4" className="right" onClick={() => this.getCapsulesOfGroup(groupList[i], true)}>
                                    {this.getCount(groupList[i].id)}
                                </Col>
                            </Row>
                            <div>
                                <HomeItemCarousel
                                    getImageFromImageAndVideos={this.props.getImageFromImageAndVideos}
                                    capsules={this.getGroupCapsules(this.props.capsules.capsulesByGroup, groupList[i].id)}
                                    onSelect={this.openCapsuleListFromHome}
                                    getFavoriteButton={this.getFavoriteButton}
                                    isFeatured={this.state.isFeatured}
                                    groupName={groupList[i].name}
                                    groupId={groupList[i].id}
                                    isGroup={true}
                                    getPublicProfileButton={this.getPublicProfileButton}
                                ></HomeItemCarousel>
                            </div>
                        </React.Fragment>
                    ))}
                </React.Fragment>
            );

            view = renderedGroups;
        // 7-9-20 - add abilty to display Most Recent if all explore groups turned off.
        // Verify whether if (isObjectExists(....)) is required and whether it fails if groups are removed vs turned off
        } else if (isObjectExists(this.props, 'capsules.exploreGroups')) {

            view = (
                <React.Fragment>
                    <Row className="group-element hover pt-3" >
                        <Col className="group-title" xs="8" sm="8" md="8" lg="8" onClick={() => this.getCapsulesOfGroup('public', true)}>
                            {this.mostRecent}
                        </Col>

                        <Col xs="4" sm="4" md="4" lg="4" className="right">
                            {isObjectExists(this.props, 'user.mostRecentCount.count') && this.props.user.mostRecentCount.count}
                            {/* 100 */}
                        </Col>
                    </Row>

                </React.Fragment>
            );

        }

        return view;

    }

    renderSearch = () => {
        return (this.props.showSearch === false ?
            <React.Fragment>

                <Col className="my-auto text-center p-0" xs="4" sm="6" md="6" lg="6">
                    <img onClick={() => {this.props.changeShowSearch(!this.props.showSearch); this.props.changeSearchTerm('');}} className="searchIcon hover" src={searchIcon} /> <span>Search</span>
                </Col>

            </React.Fragment>
            :
            <React.Fragment>
                <Col className="my-auto text-center p-0" xs="2" sm="2" md="2" lg="2">

                    <img onClick={() => {this.props.changeShowSearch(false); this.props.changeIsSearchView(false); this.props.changeSearchTerm(''); this.setState({offsetValue: 0, getMoreCapsules: true});}} className="search-icon-input my-auto" src={searchIcon} />
                </Col>
                <Col className="my-auto text-center p-0" xs="5" sm="6" md="6" lg="6">
                    <input id="search" className="group-search-input my-auto"
                        value={this.props.searchTerm}
                        onChange={(e) => this.props.changeSearchTerm(e.target.value)}
                    />
                </Col>
                <Col className="my-auto " xs="3" sm="2" md="2" lg="2">
                    <span className="my-auto navigation-button hover" onClick={() => {this.props.changeShowSearch(false); this.props.changeIsSearchView(false); this.props.changeSearchTerm(''); this.setState({offsetValue: this.state.initialOffsetValue, getMoreCapsules: true, fixSearch: true});}}>Cancel</span>
                </Col>

            </React.Fragment>
        );
    }

    hasTopLevelResults = () => {
        let results = this.props.capsules.searchResults;
        return (results.publicCapsules && results.publicCapsules.length > 0) ||
               (results.sharedCapsules && results.sharedCapsules.length > 0) ||
               (results.userCapsules && results.userCapsules.length > 0);
    }

    hasResult = (isCapsules) => {
        let result;

        switch (this.props.groupView) {
            case 'groupCapsuleList':
                result = isObjectExists(this.props, 'capsules.searchResults') && this.props.capsules.searchResults.length > 0 || this.state.groupCapsuleResult.length > 0;
                break;
            case 'userCapsuleList':
            case 'sharedCapsuleList':
                result = isObjectExists(this.props, 'capsules.searchResults') && this.props.capsules.searchResults.length > 0;
                break;
            case 'main':
                result = (this.props.capsules && this.props.capsules.searchResults) ? this.hasTopLevelResults() : false;
                break;
            default:
                result = isCapsules ? this.state.capsuleResults.length > 0 : this.state.groupResults.length > 0;

        }

        return result;

    }

    renderTopLevelResults = () => {
        let resultsObject = this.props.capsules.searchResults ? this.props.capsules.searchResults : [];
        let hasUserCapsules = isObjectExists(resultsObject, 'userCapsules') && resultsObject.userCapsules.length > 0 ? true : false;
        let hasSharedCapsules = isObjectExists(resultsObject, 'sharedCapsules') && resultsObject.sharedCapsules.length > 0 ? true : false;
        let hasPublicCapsules = isObjectExists(resultsObject, 'publicCapsules') && resultsObject.publicCapsules.length > 0 ? true : false;

        return (
            <React.Fragment>
                {hasUserCapsules &&
                <Row className="group-element group-list-divider mt-5 letter-line title-line">
                    <Col>{this.mainGroup}</Col>
                </Row>
                }
                {hasUserCapsules && this.renderResults(true, resultsObject.userCapsules)}

                {hasSharedCapsules &&
                <Row className={!hasUserCapsules ? 'group-element letter-line group-list-divider mt-5 title-line' : 'group-element letter-line'}>
                    <Col>{this.sharedCategory}</Col>
                </Row>
                }
                {hasSharedCapsules ? this.renderResults(true, resultsObject.sharedCapsules) : null}

                {hasPublicCapsules &&
                <Row className={!hasSharedCapsules && !hasUserCapsules ? 'group-element letter-line group-list-divider mt-5 title-line' : 'group-element letter-line'}>
                    <Col>{this.publicCategory}</Col>
                </Row>
                }
                {hasPublicCapsules ? this.renderResults(true, resultsObject.publicCapsules) : null}
            </React.Fragment>
        );
    }

    renderSearchResults = () => {
        let renderedResults;

        if (this.hasResult(true) && (this.props.groupView === 'main' || this.props.groupView === '') && this.state.doneTyping) {
            renderedResults = this.renderTopLevelResults();
        } else if (this.hasResult(true) && this.state.doneTyping && this.props.capsules.loaded) {
            renderedResults = (
                <React.Fragment>
                    <Row className="group-element letter-line">
                        <Col>Capsules</Col>
                    </Row>
                    {this.renderResults(true)}
                </React.Fragment>

            );
        } else if (!this.hasResult(true) && this.props.capsules.loaded && this.state.doneTyping) {
            renderedResults = (
                <div className="text-center">{this.noResults}</div>
            );
        } else {
            renderedResults = null;
        }

        return (
            <React.Fragment>
                {renderedResults}
                {this.props.capsules.loading || !this.state.doneTyping &&
                    <div className='spinner loading'>
                        <ClipLoader
                            css={this.override}
                            sizeUnit={'px'}
                            size={25}
                            color={'#123abc'}
                            loading={true}
                        />
                    </div>
                }
            </React.Fragment>
        );
    }

    renderResults = (isCapsules, topLevelResults) => {
        let itemsToRender;
        let isGroups = false;

        if (topLevelResults) {
            itemsToRender = topLevelResults;

        } else {
            switch (this.props.groupView) {
                case 'groupCapsuleList':
                    if (this.props.isExploreGroup === true || this.props.isExploreGroup === 'all') {
                        itemsToRender = this.props.capsules.searchResults;
                    } else {
                        itemsToRender = this.state.groupCapsuleResult;
                    }

                    isGroups = true;
                    break;
                case 'userCapsuleList':
                case 'sharedCapsuleList':
                case 'main':
                    itemsToRender = this.props.capsules.searchResults;
                    break;
                default:
                    itemsToRender = this.state.groupCapsuleResult;

            }
        }

        //this.props.setCapsulesToShow(itemsToRender);
        return (
            <React.Fragment>
                {
                    Object.keys(itemsToRender).map((i) => (
                        <Row key={i} className="group-element hover" >
                            <React.Fragment>
                                <Col className="hover"  onClick={isCapsules ? () => this.openCapsuleList(i, isGroups, true, itemsToRender[i].id, topLevelResults) : () => this.getCapsulesOfGroup(itemsToRender[i])}>
                                    {!itemsToRender[i].capsuleCount && this.props.getThumbnailPicture(itemsToRender[i], true)}
                                    <span className="ml-3">{getCapsuleName(itemsToRender[i])}</span>
                                    {
                                        itemsToRender[i].ownerId ?
                                            isObjectExists(itemsToRender[i], 'items.contact.organisation.organizationName') && itemsToRender[i].items.contact.organisation.organizationName !== '' && <div><span className="category ml-3">{itemsToRender[i].items.contact.organisation.organizationName}</span></div>
                                            :
                                            itemsToRender[i].category !== '' && <div><span className="category ml-3">{itemsToRender[i].category}</span></div>
                                    }
                                </Col>
                            </React.Fragment>
                            {isCapsules ? null :
                                <Col className="right">
                                    {itemsToRender[i].capsuleCount == 0 ? 'Add' : itemsToRender[i].capsuleCount}
                                </Col>
                            }
                        </Row>
                    ))
                }
                {this.props.capsules.loading &&
                <Row>
                    <Col>
                        <div className='spinner loading'>
                            <ClipLoader
                                css={this.override}
                                sizeUnit={'px'}
                                size={25}
                                color={'#123abc'}
                                loading={true}
                            />
                        </div>
                    </Col>
                </Row>
                }

            </React.Fragment>

        );
    }

    renderHomeListView = () => {
        return (
            <React.Fragment>
                {!this.props.user.publicPage &&
                <React.Fragment>
                    <Row onClick={() => this.getMyCapsules()} className="group-element hover">
                        <Col>
                            {this.mainGroup}
                        </Col>
                        <Col xs="4" sm="4" md="4" lg="4" className="right">
                            {isObjectExists(this.props, 'user.userCapsuleCount.count') ? this.props.user.userCapsuleCount.count : ''}
                        </Col>
                    </Row>

                    <Row onClick={() => this.getSharedCapsules()} className="group-element hover">
                        <Col>
                            {this.sharedGroup}
                        </Col>
                        <Col xs="4" sm="4" md="4" lg="4" className="right">
                            {this.getSharedCapsuleCount()}
                        </Col>
                    </Row>
                </React.Fragment>
                }
                {this.state.isGalleryView ? this.renderCustomGroups() : this.renderCustomGroupsList()}

            </React.Fragment>
        );
    }

    renderMyHomeGroups = () => {
        return (
            <React.Fragment>

                {this.renderCustomGroups()}
            </React.Fragment>

        );

    }

    getShowFeatured = () => {
        return isObjectExists(this.props, 'user.config.topConfig') ? this.props.user.config.topConfig.showTop : true;
    }

    isTopReady = () => {
        let result = this.props.capsules.featuredLoaded;
        if (localStorage.getItem('MUUserLogin') && this.props.user.publicPage === false) {
            result = this.props.capsules.featuredLoaded && this.props.capsules.favouritesLoaded && this.props.user.config;
        }

        if (localStorage.getItem('MUUserLogin') && this.props.user.publicPage === null) {
            result =  false;
        }

        if (this.props.user.publicPage === false) {
            result =  this.props.capsules.featuredLoaded;
        }

        if (this.props.user.publicPage === true) {
            result =  true;
        }

        return result;
    }

    renderGroupList = () => {
        //if (this.state.isGalleryView && this.isTopReady() && localStorage.getItem('MMUserLogin')
        //  && !this.props.user.capsulesLoaded) {
        //      //get capsules
        //  }
        return (
            <Container className="group-list">
                {this.state.isGalleryView && this.isTopReady() && this.getShowFeatured() && this.renderFeatured()}
                {localStorage.getItem('MUUserLogin') && this.props.user.capsulesLoaded || this.props.user.publicPage ?
                    this.state.isGalleryView && this.isTopReady() ? this.renderMyHomeGroups() : this.renderHomeListView()
                    :
                    null
                    /*}<React.Fragment>
                        <Row className="text-center group-element">
                            <Col className="navigation-button" onClick={() => this.props.navigateTo('login')}>{this.pleaseLogin}</Col>
                        </Row>
                    </React.Fragment>*/
                }

                {this.state.isGalleryView ? ((localStorage.getItem('MUUserLogin') && this.isTopReady()) || (!localStorage.getItem('MUUserLogin'))) && this.props.user.publicPage === false && this.renderExploreGroups() :  this.props.user.publicPage === false && this.renderExploreGroupsList()}

                {localStorage.getItem('MUUserLogin') && !this.props.user.publicPage && this.renderOptions()}

            </Container>

        );
    }

    getShownExploreGroupCount = () => {
        let count = 0;
        let textToShow = '';

        if (isObjectExists(this.props, 'user.config.explore_category') && isObjectExists(this.props, 'capsules.exploreGroups')) {
            let configGroups = this.props.user.config.explore_category;

            for (let i in configGroups) {
                if (configGroups[i].display) {
                    count++;
                }
            }

            textToShow = count + ' of ' + this.getExploreLength(this.props.capsules.exploreGroups.length);

        } else if (isObjectExists(this.props, 'capsules.exploreGroups')) {
            textToShow =  this.getExploreLength(this.props.capsules.exploreGroups.length) + ' of ' + this.getExploreLength(this.props.capsules.exploreGroups.length);
        }
        return textToShow;
    }

    getExploreLength = (exploreGroup) => {
        return parseInt(exploreGroup+1) || 0;
    }

    getCountOfUserGroups = () => {
        return this.props.capsules && this.props.capsules.groups && this.props.capsules.groups.length !== 0 ? ' (' + this.props.capsules.groups.length + ')' : '';
    }

    manageFavorite = (e, isAdd, capsule) => {

        e.stopPropagation();
        //8-3-2022 added check for this.props.capsules.loaded to debounce + add. Watch if issues since loaded used for variou purposes.;
        if (isAdd && !this.props.capsules.doNotSendFav) {
            this.props.dispatch(capsuleActions.addToFavourites(capsule.id, capsule));
        } else if (!isAdd) {
            this.props.dispatch(capsuleActions.removeFromFavourites(capsule.id, capsule));
        }
    }

    getFavoriteButton = (capsule, isFeatured) => {
        if (isObjectExists(this.props, 'capsules.favourites') && localStorage.getItem('MUUserLogin')) {
            return (
                <img src={isFeatured && !this.isInFavorites(capsule.id) ? exitIcon : plusButton}
                    className={isFeatured && !this.isInFavorites(capsule.id) ? 'favorite-button option-img plus' : 'favorite-button option-img minus'}
                    onClick={isFeatured && !this.isInFavorites(capsule.id) ? (e) => this.manageFavorite(e, true, capsule) : (e) => this.manageFavorite(e, false, capsule)}>

                </img>);
        } else {
            return null;
        }
    }

    openCreate = () => {

        this.props.setGroupIdToAddTo(this.props.groupToEdit.id);
        this.props.changeView('createCard');
        this.setState({isOptionsOpen: false});
        this.props.changeIsCreating(true);
        this.props.changeIsNewCapsule(true);
    }

    renderCarousel = () => {

        if (this.state.capsulesInCarousel.length > 0) {
            return (
                <Row className={'carousel-container navigation gradient-background'}>

                    <Col className="">
                        <NavigationCarousel
                            index={this.state.slideIndex}
                            capsules={this.state.capsulesInCarousel}
                            user={this.props.user}
                            parentStateUpdater={this.afterSlide}
                            getImageFromImageAndVideos={this.props.getImageFromImageAndVideos}
                        />
                    </Col>
                </Row>
            );
        } else {
            return null;
        }
    }

    renderOptions = (inGroup, myCapsules) => {
        if (this.state.isOptionsOpen === false) {
            return (
                <div id="options" className="options" >
                    <Row className="my-auto right">
                        <Col className="right align-self-end"><img id="option-img plus" onClick={() => this.setState({isOptionsOpen: true})} className="right hover option-img plus"  src={exitIcon}/></Col>
                    </Row>
                </div>
            );
        } else {
            if (inGroup) {
                return (
                    <div id="options" className="options">
                        <Row className="my-auto right">
                            <Col >
                        Create Capsule<img className="right hover" src={createCapsuleIcon} onClick={() => this.openCreate()}/>
                            </Col>
                        </Row>
                        <Row className="my-auto right">
                            <Col>
                        Delete Group<img  id="option-img" className="right hover " src={exitIcon} onClick={() =>{ this.onDelete(this.props.groupToEdit.id); this.setState({isOptionsOpen: false}); this.props.changeView('main');}}/>
                            </Col>
                        </Row>
                        <Row className="my-auto right">
                            <Col>
                        Revoke Shares<img  id="option-img" className="right hover " src={addGroupIcon} onClick={() =>{this.setState({isOptionsOpen: false}); this.props.changeGroupView('revokeSharedGroupUsers', 'home 2346');}}/>
                            </Col>
                        </Row>
                        <Row className="my-auto right">
                            <Col>
                                <img onClick={() => this.setState({isOptionsOpen: false})} className="hover option-img minus" id="option-img" src={plusButton} />
                            </Col>
                        </Row>
                    </div>
                );
            } else if (myCapsules) {
                return (
                    <div id="options" className="options">
                        <Row className="my-auto right">
                            <Col >
                        Create Capsule<img className="hover" src={createCapsuleIcon} onClick={() => {this.props.changeView('createCard'); this.setState({isOptionsOpen: false, isFromGroups: true, groupName: this.mainGroup}); this.props.changeIsNewCapsule(true); this.props.changeIsCreating(true);}}/>
                            </Col>
                        </Row>
                        <Row className="my-auto right">
                            <Col className="right">
                                <img className="right hover option-img minus" src={plusButton} onClick={() => this.setState({isOptionsOpen: false})}/>
                            </Col>
                        </Row>
                    </div>
                );
            } else {
                return (

                    <div id="options" className="options">
                        <Row className="my-auto right">
                            <Col >
                        Create Capsule<img className="right hover" src={createCapsuleIcon} onClick={() => {this.props.setGroupIdToAddTo(''); this.props.changeView('createCard'); this.setState({isOptionsOpen: false, isFromGroups: true, groupName: this.mainGroup}); this.props.changeIsNewCapsule(true); this.props.changeIsCreating(true);}}/>
                            </Col>
                        </Row>
                        <Row className="my-auto right">
                            <Col >
                        Add Group<img className="right hover" src={addGroupIcon} onClick={() =>{ this.openAddGroup(); this.setState({isOptionsOpen: false});}}/>
                            </Col>
                        </Row>
                        <Row className="my-auto right">
                            <Col className="right">
                                <img className="right hover option-img minus" src={plusButton} onClick={() => this.setState({isOptionsOpen: false})}/>
                            </Col>
                        </Row>
                    </div>

                );
            }
        }
    }

    sharedGroupEdit = () => {
        for (let i in this.props.user.sharedGroups) {
            if (this.props.user.sharedGroups[i].id === this.props.groupToEdit.id) {
                return true;
            }
        }
        return false;
    }
    canEdit = () => {
        return -1 !== AdminConfig.adminIds.indexOf(this.props.user.id) ? true : false;
    }

    getListHeader = () => {
        if (this.props.groupView === 'main' || this.props.groupView === '') {
            return null;

        } else if ((this.props.groupView === 'groupCapsuleList' && !this.props.isExploreGroup && !this.sharedGroupEdit()) || (!this.sharedGroupEdit() && this.props.isExploreGroup && this.canEdit() && this.props.groupName !== 'Most Recent')) {
            return (
                <React.Fragment>
                    <Col xs="9" sm="9" md="9" lg="9" className="my-auto group-name">
                        {this.props.groupToEdit.name === '' ? 'Most Recent' : this.props.groupToEdit.name}
                    </Col>

                    <Col className="right" md="3" lg="3" sm="3" xs="3"><span className="navigation-button" onClick={() => this.props.changeGroupView('editGroup')}>Edit</span></Col>

                </React.Fragment>
            );
        } else {
            return (
                <Col xs="12" sm="12" md="12" lg="12" className="my-auto group-name">
                    {this.props.groupToEdit.name === '' ? 'Most Recent' : this.props.groupToEdit.name}
                </Col>
            );
        }
    }

    isInFavorites = (id) => {

        for (let i in this.props.capsules.favourites) {
            if (this.props.capsules.favourites[i].id === id) {
                return true;
            }
        }
        return false;
    }

    switchFeatured = (value) => {
        let valueToSwitch = value || !this.state.isFeatured;
        if (((!this.props.user.withKey && this.props.user.withToken) || localStorage.getItem('MUUserLogin'))) {
            this.setState({isFeatured: valueToSwitch});
        }

    }

    getFeaturedCapsules = () => {
        let capsules = [];
        if (this.props.user.publicPage) {
            return this.props.capsules.favourites;
        }
        capsules = this.getIsFeatured() ? this.props.capsules.featuredCapsules || [] : this.props.capsules.favourites || [];

        return capsules;
    }

    getTopName = () => {
        if (this.props.user.publicPage) {
            return 'Featured';
        }
        return this.getIsFeatured() ? 'Featured' : 'Favorites';
    }

    renderFeatured = () => {
        return (
            <FeaturedComponent
                getImageFromImageAndVideos={this.props.getImageFromImageAndVideos}
                capsules={this.getFeaturedCapsules()}
                openFavorites={() => this.setState({isFavoriteView: true})}
                onSelect={this.openCapsuleListFromHome}
                getFavoriteButton={this.getFavoriteButton}
                groupName={this.getTopName()}
                isFeatured={this.getIsFeatured()}
                switchFeatured={this.switchFeatured}
                dispatch={this.props.dispatch}
                publicPage={this.props.user.publicPage}
                getPublicProfileButton={this.getPublicProfileButton}
                user={this.props.user}
                isFileImage={this.props.isFileImage}
            ></FeaturedComponent>

        );
    }

    getIsFeatured = () => {
        return isObjectExists(this.props, 'user.config.topConfig') ? this.props.user.config.topConfig.isFeatured : true;
    }

    changeShowFeatured = (value) => {
        this.setState({showFeatured: value});
    }

    render() {
        const view = this.props.groupView;
        let exploreGroups = [{id: 'most_recent', name: 'Most Recent'}].concat(this.props.capsules.exploreGroups);
        let userGroups = [{id: 'my_capsules', name: this.mainGroup}, {id: 'shared_capsules', name: this.sharedCategory}].concat(this.props.capsules.groups);

        let capsules = this.props.capsules;
        capsules.capsule = this.props.capsuleList;
        switch (view) {
            case 'main':    //gallery view
                return  (
                    <React.Fragment>
                        {this.state.isOptionsOpen &&
                        <Helmet>
                            <style>
                                {
                                    '.group-list > :not(#options) { filter: blur(2px); pointer-events: none; }'
                                }
                            </style>

                        </Helmet>
                        }

                        <Container id="group-container" className={(view === 'search' || view === 'main') ? 'header-padding' : ''}>
                            <ModalView isOpen={this.state.removeGroupFromSharedWithMe}
                                toggle={() => this.setState({removeGroupFromSharedWithMe: !this.state.removeGroupFromSharedWithMe})}
                                onContinue={this.deleteGroupFromSharedWithMe}
                                onCancel={() => {this.setState({removeGroupFromSharedWithMe: !this.state.removeGroupFromSharedWithme});}}
                                title="Remove Shared group"
                                modalType="removeShareGroupFromSharedWithMe"
                            />
                            <ModalView isOpen={this.state.groupShareReady}
                                toggle={() => this.setState({groupShareReady: !this.state.groupShareReady})}
                                title="Get Group Share Link"
                                modalType="groupShareReady"
                                urlGroupShare={process.env.REACT_APP_BASE_URI + '/group/?group=' +  this.state.groupToShare + '&share_token=' + this.props.user.groupShareToken}
                                copyIsDone={this.copyIsDone}
                            />
                            <ModalView isOpen={this.state.copied}
                                toggle={() => this.setState({copied: !this.state.copied})}
                                modalType="copyGroupShare"
                            />
                            <div className="fixed-top container">
                                {this.renderHeader()}
                            </div>
                            {this.isTopReady() ?
                                <Container className={this.props.isSearchView ? 'no-overflow' : 'p-0'}>
                                    {this.getListHeader()}
                                    {this.state.isUserGroupView &&

                                <FilterGroups capsules={this.props.capsules}
                                    dispatch={this.props.dispatch}
                                    user={this.props.user}
                                    groups={userGroups}
                                    setExploreGroups={() => this.setState({exploreGroupsSet: false})}
                                    exploreGroups={exploreGroups}
                                    onBack={() => this.setState({isUserGroupView: false})}
                                    onRemoveGroup={this.props.onRemoveGroup}
                                    onAddGroup={this.props.onAddGroup}
                                    getCapsulesOfGroup={this.getCapsulesOfGroup}
                                    isExploreGroups={false}
                                    switchFeatured={this.switchFeatured}
                                    isFeatured={this.state.isFeatured}
                                    changeShowFeatured={this.changeShowFeatured}
                                    showFeatured={this.state.showFeatured}
                                    groupItem={this.state.groupItem}
                                />}

                                    {this.state.isFavoriteView &&

                                <FavoriteView favourites={this.props.capsules.favourites}
                                    dispatch={this.props.dispatch}
                                    back={() => this.setState({isFavoriteView: false})}
                                    onRemoveGroup={this.props.onRemoveGroup}
                                    onAddGroup={this.props.onAddGroup}
                                    setGroupsToAdd={this.props.setGroupsToAdd}
                                    getCapsulesOfGroup={this.getCapsulesOfGroup}
                                    getThumbnailPicture={this.props.getThumbnailPicture}
                                />}
                                    {!this.props.isExploreGroupView && !this.state.isUserGroupView && !this.state.isFavoriteView ? this.props.isSearchView ? this.renderSearchResults() : this.renderGroupList() : null}
                                </Container>
                                :
                                null}
                        </Container>
                    </React.Fragment>
                );
            case 'capsuleList':    //speciic to explore
                return <Explore
                    isFromGroups={false}
                    isPreview={false}
                    capsules={capsules}
                    capsuleList={this.props.searchTerm && this.props.searchTerm !== '' ? this.props.capsules.searchResults : capsules}
                    groupName={this.props.groupName}
                    backToGroups={this.backToGroups}
                    openEditCard={this.props.openEditCard}
                    deleteCapsule={this.props.deleteCapsule}
                    changeStartingIndex={this.props.changeStartingIndex}
                    startingIndex={parseInt(this.props.startingIndex)}
                    logout={this.props.logout}
                    navigateTo={this.props.changeView}
                    getThumbnailPicture={this.props.getThumbnailPicture}
                    getImageFromImageAndVideos={this.props.getImageFromImageAndVideos}
                    listenStatus={this.props.listenStatus}
                    //getStatusOfMedia={this.props.getStatusOfMedia}
                    getImageScalingParameter={this.props.getImageScalingParameter}
                    typeOfList={this.props.typeOfList}
                    viewedGroup={this.props.viewedGroup}
                    searchTerm={this.props.searchTerm}
                    offsetValue={this.state.offsetValue}
                    increaseOffset = {this.increaseOffset}
                    setCapsuleToEdit={this.props.setCapsuleToEdit}
                    shouldShowPublicProfileButton={this.shouldShowPublicProfileButton}
                    groupView={this.props.groupView}
                />;
            case 'groupCapsuleList':     //Private user group
            case 'userCapsuleList':      //My Contact Capsules group
            case 'sharedCapsuleList':    //shared capsule group
                return (
                    <React.Fragment>
                        <ModalView isOpen={this.state.removeFromGroup}
                            toggle={() => this.setState({removeFromGroup: !this.state.removeFromGroup})}
                            onContinue={this.deleteFromSharedGroup}
                            onCancel={() => {this.setState({removeFromGroup: !this.state.removeFromGroup});}}
                            title="Remove capsule"
                            modalType="removeFromGroup"
                        />
                        {this.state.isOptionsOpen &&
                        <Helmet>
                            <style>
                                {
                                    '.group-list > :not(#options) { filter: blur(2px); pointer-events: none; }'
                                }
                            </style>

                        </Helmet>
                        }
                        <Container className="">
                            <div className="fixed-top container bg-black">
                                {this.renderHeader()}
                            </div>
                            <Row id="capsule-view-2">
                                <Container id='scroll-area' className="pt-5">
                                    <Row className="capsule-header group-element">
                                        {this.getListHeader()}
                                    </Row>
                                    {this.props.isSearchView ? this.renderSearchResults(view) : this.renderCapsules(view)}

                                </Container>

                            </Row>
                        </Container>
                    </React.Fragment>);
            case 'editGroup':
                return (
                    <Container className="container-width ">
                        <ModalView isOpen={this.state.isPrivateCapsules}
                            toggle={() => this.setState({isPrivateCapsules: !this.state.isPrivateCapsules})}
                            onContinue={() => this.setCapsulesToPublicAndSave()}
                            onCancel={() => {this.setState({isPrivateCapsules: !this.state.isPrivateCapsules});}}
                            title="Cannot add private capsules to public directories"
                            modalType="privateCapsules"
                        />
                        <ModalView isOpen={this.state.isPublicCapsules}
                            toggle={() => this.setState({isPublicCapsules: !this.state.isPublicCapsules})}
                            onContinue={this.setCapsulesToPublicAndSave}
                            onCancel={() => {this.setState({isPublicCapsules: !this.state.isPublicCapsules});}}
                            title="Removed Capsules are public"
                            modalType="publicCapsules"
                        />

                        {this.renderHeader()}
                        <Row  className='capsule-view-0 group-container capsule-view-1'>
                            {this.renderCapsulesOfGroup()}
                        </Row>
                        <Row className={'carousel-container social'}></Row>
                        {this.renderCarousel()}
                    </Container>);
            case 'createGroup':
                return (

                    <Container className="container-width">
                        {this.renderHeader()}
                        <Row  className='capsule-view-1 group-container'>
                            {this.renderCreateGroup()}
                        </Row>
                    </Container>);
            case 'revokeSharedGroupUsers':

                return (
                    <React.Fragment>
                        <Container className="m-auto">
                            <Col xs="5" sm="4" md="4" lg="4" className="my-auto">
                                <img src={backButton} className="back-button hover" onClick={() => this.backToGroups()}/>
                                <span className="navigation-button" onClick={() => this.backToGroups()}>Groups</span>
                            </Col>
                            <RevokeGroupShares
                                back={this.back}
                                user={this.props.user}
                                dispatch={this.props.dispatch}
                                backToGroups={this.backToGroups}
                                renderHeader={this.renderHeader}
                                capsules={this.props.capsules}
                                groupId={this.props.groupToEdit.id}
                                groupName={this.props.groupToEdit.name}
                            />
                        </Container>
                    </React.Fragment>
                );
            case 'profileSettings':
                return <ProfileHome
                    user={this.props.user}
                    renderHeader={this.renderHeader}
                    dispatch={this.props.dispatch}
                    logout={this.props.logout}
                    backToGroups={this.backToGroups}
                    capsules={this.props.capsules}
                    listenStatus={this.props.listenStatus}
                    setCheckingProgressOf={this.props.setCheckingProgressOf}
                    checkingProgressOf={this.props.checkingProgressOf}
                    //getStatusOfMedia={this.props.getStatusOfMedia}
                    checkIfUploading={this.props.checkIfUploading}
                    isFileImage={this.props.isFileImage}
                />;

        }
    }

}

Home.propTypes = {
    dispatch:                   PropTypes.func,
    capsule:                    PropTypes.string,
    capsules:                   PropTypes.object,
    capsuleList:                PropTypes.array,
    viewToRender:               PropTypes.string,
    user:                       PropTypes.object,
    match:                      PropTypes.object,
    history:                    PropTypes.object,
    logout:                     PropTypes.func,
    navigateTo:                 PropTypes.func,
    changeFooterTo:             PropTypes.func,
    openEditCard:               PropTypes.func,
    groupView:                  PropTypes.string,
    changeGroupView:            PropTypes.func,
    groupName:                  PropTypes.string,
    changeGroupName:            PropTypes.func,
    deleteCapsule:              PropTypes.func,
    changeView:                 PropTypes.func,
    changeStartingIndex:        PropTypes.func,
    startingIndex:              PropTypes.number,
    setGroupIdToAddTo:          PropTypes.func,
    getIndexOfNewCapsule:       PropTypes.func,
    viewedGroup:                PropTypes.string,
    setViewedGroup:             PropTypes.func,
    changeIsCreating:           PropTypes.func,
    isExploreGroup:             PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    setIsExploreGroup:          PropTypes.func,
    home:                       PropTypes.bool,
    setIsExploreGroupView:      PropTypes.func,
    groupsToAdd:                PropTypes.object,
    onRemoveGroup:              PropTypes.func,
    onAddGroup:                 PropTypes.func,
    isExploreGroupView:         PropTypes.bool,
    setGroupsToAdd:             PropTypes.func,
    getThumbnailPicture:        PropTypes.func,
    getImageFromImageAndVideos: PropTypes.func,
    listenStatus:               PropTypes.func,
    //getStatusOfMedia:           PropTypes.func,
    getImageScalingParameter:   PropTypes.func,
    typeOfList:                 PropTypes.string,
    changeTypeOfList:           PropTypes.func,
    groupToEdit:                PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    setGroupToEdit:             PropTypes.func,
    searchTerm:                 PropTypes.string,
    changeSearchTerm:           PropTypes.func,
    isSearchView:               PropTypes.bool,
    changeIsSearchView:         PropTypes.func,
    showSearch:                 PropTypes.bool,
    changeShowSearch:           PropTypes.func,
    groupHistory:               PropTypes.array,
    addToGroupHistory:          PropTypes.func,
    getLatestFromGroupHistory:  PropTypes.func,
    resetGroupHistory:          PropTypes.func,
    setCheckingProgressOf:      PropTypes.func,
    checkingProgressOf:         PropTypes.string,
    setCapsuleToEdit:           PropTypes.func,
    setCapsulesToShow:          PropTypes.func,
    fromHome:                   PropTypes.bool,
    setFromHome:                PropTypes.func,
    changeIsNewCapsule:         PropTypes.func,
    checkIfUploading:           PropTypes.func,
    isFileImage:                PropTypes.func

};

function mapStateToProps(state) {
    const { capsules, user } = state;
    return {
        capsules,
        user
    };
}

const connectedHomePage = connect(mapStateToProps)(Home);
export { connectedHomePage as Home };
