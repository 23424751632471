import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { CapsuleView } from '../capsuleView/CapsuleView';
import SocialView from '../socialView/SocialView';
import { isWebsiteSocial } from '../../utils/Helpers';

export class CapsuleContainer extends React.Component {
    static defaultProps = {
        updateParentStateFunc: function() {}
    };

    constructor (props) {
        super(props);
        this.state = {
            showSearch:   false,
            nameToSearch: '',
            isScrollable: false,
            isWrap:       false
        };
    }

    componentDidMount() {
        if (this.props.websiteUrl) {

            document.documentElement.style.overflow = 'hidden';
            document.documentElement.style.position = 'fixed';
        }

    }

    componentWillUnmount() {
        document.documentElement.style.overflow = 'auto';
        document.documentElement.style.position = 'relative';
    }

    componentDidUpdate() {

        if (this.props.websiteUrl && -1 !== this.props.websiteUrl.indexOf('wrap') && !this.state.isWrap && !this.props.isEdit) {
            document.documentElement.style.overflow = 'hidden';
            document.documentElement.style.position = 'fixed';
            this.setState({isWrap: true});
        }

        if ((this.props.isEdit || (this.props.websiteUrl && -1 === this.props.websiteUrl.indexOf('wrap'))) && this.state.isWrap) {
            document.documentElement.style.overflow = 'auto';
            document.documentElement.style.position = 'relative';
            this.setState({isWrap: false});
        }
    }

    determineCapsuleViewClass = (view) => {
        let classes = 'capsule-view-0';

        if ((this.props.isShare && !this.props.groupShare) || !this.props.showNav) {
            classes = 'capsule-view-3';
        } else if (view === 'media' || view === 'addCard') {
            classes = 'capsule-view opened gradient';
        } else if ((this.props.fullscreenCapsule) || view ==='chatbotView') {
            classes = 'capsule-view-2';
        }

        return classes;
    }

    determineColumnClass = (view, socialType) => {

        let classes = 'capsule-container-max';

        if (socialType === 'main' && view === '' && this.props.websiteUrl) {
            if (isWebsiteSocial(this.props.websiteUrl) !== false) {
                classes = 'p-0';
            } else {
                classes = 'p-0 iframe';
            }
        }

        if (this.props.socialType !== 'main' && this.props.socialType !== 'capsuleList') {
            classes = 'p-0';
        }

        if (view === 'chatbotView') {
            classes += ' bot-padding';
        }
        // eslint-disable-next-line no-console
        console.log('laurie', classes);
        return classes;
    }

    updateStatusFromVideoPlayer = (muteState, fsState) => {
        this.setState({isFullscreen: fsState, isMuted: muteState});
    }

    openWebsite = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null;
    }

    render() {
        let backgroundObject = this.props.getMenuBackground(this.props.capsule);

        return (

            <Row
                id='capsule-view'
                className={
                    this.determineCapsuleViewClass(this.props.viewToShow)
                }
                style={backgroundObject}
            >
                <Col id="capsule-width lin-gradient transition" className={this.determineColumnClass(this.props.viewToShow, this.props.socialType)}>
                    {this.props.socialType === 'main' || this.props.socialType === 'capsuleList' ?
                        <React.Fragment>
                            {this.props.viewToShow !== '' || this.props.viewToShow === '' && this.props.websiteUrl && isWebsiteSocial(this.props.websiteUrl) !== 'twitter' ? <div className="section-background"></div> : null}
                            <CapsuleView
                                capsuleToShow={this.props.capsule}
                                onMenuChange={this.props.changeMenuToShow}
                                viewToShow={this.props.viewToShow}
                                setGotGroups={this.setGotGroups}
                                searchInitiated={this.state.searchInitiated}
                                user={this.props.user}
                                config={this.props.config}
                                isVideoBackground={this.props.isVideoBackground}
                                getImageFromImageAndVideos={this.props.getImageFromImageAndVideos}
                                getTransformationClassBasedOnOrientation={this.props.getTransformationClassBasedOnOrientation}
                                getImageResizerParameters={this.props.getImageResizerParameters}
                                getThumbnailPicture={this.props.getThumbnailPicture}
                                backgroundLoaded={this.props.backgroundLoaded}
                                openEditCard={this.props.openEditCard}
                                deleteCapsule={this.props.deleteCapsule}
                                isFromGroups={this.props.isFromGroups}
                                isPreview={this.props.isPreview}
                                dispatch={this.props.dispatch}
                                isEdit={this.props.isEdit}
                                websiteUrl={this.props.websiteUrl !== '' ? this.props.websiteUrl : null}
                                capsules={this.props.capsules}
                                listenStatus={this.props.listenStatus}
                                //getStatusOfMedia={this.props.getStatusOfMedia}
                                isFullscreen={this.props.isFullscreen}
                                isMuted={this.props.isMuted}
                                updateParentStateFunc={this.props.updateParentStateFunc}
                                getImageScalingParameter={this.props.getImageScalingParameter}
                                fullscreenCapsule={this.props.fullscreenCapsule}
                                setFullscreen={() => this.props.setFullscreen()}
                                isCapsuleFullscreen={this.props.fullscreenCapsule}
                                groupName={this.props.groupName}
                                gotCapsuleSharedUsers={this.props.gotCapsuleSharedUsers}
                                setGotCapsuleSharedUsers={this.props.setGotCapsuleSharedUsers}
                                capsuleSharedUsers={this.props.capsuleSharedUsers}
                                getSharedUsers={this.props.getSharedUsers}
                                revokeShare={this.props.revokeShare}
                                revokedUserShare={this.props.revokedUserShare}
                                revokedUser2Id={this.props.revokedUser2Id}
                                revokedCapsuleId={this.props.revokedCapsuleId}
                                setRevokedUserShare={this.props.setRevokedUserShare}
                            />

                        </React.Fragment>
                        :
                        <SocialView
                            socialType={this.props.socialType}
                            capsuleToShow={this.props.capsule}
                            dispatch={this.props.dispatch}
                            capsules={this.props.capsules}
                            onClick={this.openWebsite}
                        />
                    }

                </Col>

            </Row>

        );
    }
}

CapsuleContainer.propTypes = {
    capsule:                                  PropTypes.object,
    capsules:                                 PropTypes.object,
    dispatch:                                 PropTypes.func,
    config:                                   PropTypes.object,
    changeMenuToShow:                         PropTypes.func,
    user:                                     PropTypes.object,
    isVideoBackground:                        PropTypes.bool,
    searchInitiated:                          PropTypes.bool,
    socialType:                               PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    navigationSlideIndex:                     PropTypes.number,
    setGotGroups:                             PropTypes.func,
    viewToShow:                               PropTypes.string,
    getMenuBackground:                        PropTypes.func,
    getTransformationClassBasedOnOrientation: PropTypes.func,
    getImageFromImageAndVideos:               PropTypes.func,
    getImageResizerParameters:                PropTypes.func,
    expandedState:                            PropTypes.number,
    getThumbnailPicture:                      PropTypes.func,
    backgroundLoaded:                         PropTypes.bool,
    openEditCard:                             PropTypes.func,
    deleteCapsule:                            PropTypes.func,
    isFromGroups:                             PropTypes.bool,
    isPreview:                                PropTypes.bool,
    isEdit:                                   PropTypes.bool,
    websiteUrl:                               PropTypes.string,
    listenStatus:                             PropTypes.func,
    //getStatusOfMedia:                         PropTypes.func,
    isFullscreen:                             PropTypes.bool,
    isMuted:                                  PropTypes.bool,
    updateParentStateFunc:                    PropTypes.func,
    getImageScalingParameter:                 PropTypes.func,
    fullscreenCapsule:                        PropTypes.bool,
    isShare:                                  PropTypes.bool,
    showNav:                                  PropTypes.bool,
    setFullscreen:                            PropTypes.func,
    isCapsuleFullscreen:                      PropTypes.bool,
    groupName:                                PropTypes.string,
    capsuleSharedUsers:                       PropTypes.array,
    gotCapsuleSharedUsers:                    PropTypes.bool,
    getSharedUsers:                           PropTypes.func,
    setGotCapsuleSharedUsers:                 PropTypes.func,
    revokeShare:                              PropTypes.func,
    revokedUserShare:                         PropTypes.bool,
    revokedUser2Id:                           PropTypes.string,
    revokedCapsuleId:                         PropTypes.string,
    setRevokedUserShare:                      PropTypes.func,
    groupShare:                               PropTypes.bool
};
